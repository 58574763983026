import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

/**
 * roteamento
 */
import AppRoutes from './main/route/Route';

/**
 * autenticacao
 */
import { AuthProvider } from './data/context/AuthContext';

/**
 * import css Bootstrap
 */
import 'bootstrap/dist/css/bootstrap.min.css';

/**
 * style global
 */
import { GlobalStyled } from './presentation/style/global/globalStyled';

/**
 * sibebar
 */
import { SidebarProvider } from './data/context/SidebarContext';

/**
 * LoadingProvider
 */
import { LoadingProvider } from './data/context/LoadingContext';
import { AlertProvider } from './data/context/AlertContext';
import { ReactNotifications } from 'react-notifications-component';
import { ServerEnvironment } from './presentation/component/serverEnvironment/server-environment-component';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AlertProvider>
    <AuthProvider>
      <GlobalStyled>
        <SidebarProvider>
          <LoadingProvider>
            <ReactNotifications />
            <AppRoutes />
          </LoadingProvider>
        </SidebarProvider>
      </GlobalStyled>
    </AuthProvider>
    <ServerEnvironment />
  </AlertProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
