import React from 'react';
import { useForm } from 'react-hook-form';

import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { CreateClinicPageStyled } from './CreateClinicPageStyled';

import logo from '../../images/logo_branco.png';
import { EstadosBrasil } from '../../../main/constant/UfConstant';

import { FaEye } from 'react-icons/fa6';
import { TfiSave } from 'react-icons/tfi';
import { FaArrowLeft } from 'react-icons/fa';
import { IoLocationOutline } from 'react-icons/io5';
import { PiIdentificationCard } from 'react-icons/pi';
import { AiOutlineFileProtect } from 'react-icons/ai';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { InputsAddClinic, PropsAddClinic } from './CreateClinicPageType';

/**
 * CreateClinicPage
 * @param createClinic
 * @constructor
 */
const CreateClinicPage: React.FC<PropsAddClinic> = ({ addClinic, comeBack }: PropsAddClinic) => {
  /** useForm */
  const { register, getValues, setValue } = useForm<InputsAddClinic>();

  /**
   * handleAddClinic
   */
  const handleAddClinic = async () => {
    const model = {
      id: getValues('id') ?? '',
      name: getValues('name') ?? '',
      status: getValues('status') ?? '',
      tradeName: getValues('tradeName') ?? '',
      accountType: getValues('accountType') ?? '',
      document_CNPJ: getValues('document_CNPJ') ?? '',
      document_CPF: getValues('document_CPF') ?? '',
      contact_Phone: getValues('contact_Phone') ?? '',
      contact_Email: getValues('contact_Email') ?? '',
      address_ZipCode: getValues('address_ZipCode') ?? '',
      address_Street: getValues('address_Street') ?? '',
      address_Number: getValues('address_Number') ?? '',
      address_Complement: getValues('address_Complement') ?? '',
      address_Neighborhood: getValues('address_Neighborhood') ?? '',
      address_City: getValues('address_City') ?? '',
      address_State: getValues('address_State') ?? '',
      address_Country: getValues('address_Country') ?? '',
      responsible_Name: getValues('responsible_Name') ?? '',
      responsible_Email: getValues('responsible_Email') ?? '',
      responsible_Document_CRO: getValues('responsible_Document_CRO') ?? '',
      responsible_Document_RG: getValues('responsible_Document_RG') ?? '',
      responsible_Document_RG_IssuingAuthority: getValues('responsible_Document_RG_IssuingAuthority') ?? '',
      setup_NumberOfChairs: getValues('setup_NumberOfChairs') ?? '',
      setup_DentistAdmin: getValues('setup_DentistAdmin') ?? '',
      setup_StandardAppointmentTime: getValues('setup_StandardAppointmentTime') ?? '',
      setup_WeeklyWorkingHours: getValues('setup_WeeklyWorkingHours') ?? '',
      setup_WeekDays: getValues('setup_WeekDays') ?? '',
    };

    await addClinic(model);
  };

  return (
    <CreateClinicPageStyled>
      <Container className="container-header">
        <div className="imagen">
          <Image src={logo} width={200} />
        </div>
      </Container>
      <Container className="container-field">
        <Form>
          <div>
            <div>
              <div className="title">
                <PiIdentificationCard />
                <h6>Clínica</h6>
              </div>
              <div>
                <Row className="row-field">
                  <Col>
                    <Form.Check type="radio" label="Jurídica" />
                    <Form.Check type="radio" label="Fisica" />
                  </Col>
                  <Col>
                    <Button size="sm" className="btn-salvar-clinica" style={{ float: 'right' }} onClick={handleAddClinic}>
                      <TfiSave width={30} style={{ paddingRight: '5px' }} />
                      Salvar
                    </Button>
                    <Button
                      size="sm"
                      className="btn-salvar-clinica"
                      variant="outline-primary"
                      style={{ float: 'right', marginRight: '5px' }}
                      onClick={comeBack}>
                      <FaArrowLeft width={30} style={{ paddingRight: '5px' }} />
                      Voltar
                    </Button>
                  </Col>
                </Row>

                <Row className="row-field">
                  <Col>
                    <Form.Label>Nome da clínica</Form.Label>
                    <Form.Control placeholder="Nome da clínica" {...register('name')} />
                  </Col>
                  <Col>
                    <Form.Label>Nome fantasia</Form.Label>
                    <Form.Control placeholder="Nome fantasia" {...register('tradeName')} />
                  </Col>
                  <Col>
                    <Form.Label>CNPJ</Form.Label>
                    <Form.Control placeholder="CNPJ" {...register('document_CNPJ')} />
                  </Col>
                </Row>
                <Row className="row-field">
                  <Col>
                    <Form.Label>E-mail *</Form.Label>
                    <Form.Control placeholder="E-mail *" {...register('contact_Email')} />
                  </Col>
                  <Col>
                    <Form.Label>Telefone</Form.Label>
                    <Form.Control placeholder="Telefone" {...register('contact_Phone')} />
                  </Col>
                  <Col>
                    <Form.Label>Quantidade cadeira</Form.Label>
                    <Form.Control placeholder="Quantidade cadeira" {...register('setup_NumberOfChairs')} />
                  </Col>
                  <Col>
                    <Form.Label>CRO do Responsável</Form.Label>
                    <Form.Control placeholder="CRO do Responsável" {...register('responsible_Document_CRO')} />
                  </Col>
                </Row>
              </div>
            </div>
            <div>
              <div className="title">
                <IoLocationOutline />
                <h6>Endereço</h6>
              </div>
              <div>
                <Row className="row-field">
                  <Col>
                    <Form.Label>Cep</Form.Label>
                    <Form.Control placeholder="Cep" {...register('address_ZipCode')} />
                  </Col>
                  <Col>
                    <Form.Label>Endereço</Form.Label>
                    <Form.Control placeholder="Endereço" {...register('address_Street')} />
                  </Col>
                </Row>
                <Row className="row-field">
                  <Col>
                    <Form.Label>Número</Form.Label>
                    <Form.Control placeholder="Número" {...register('address_Number')} />
                  </Col>
                  <Col>
                    <Form.Label>Complemento</Form.Label>
                    <Form.Control placeholder="Complemento" {...register('address_Complement')} />
                  </Col>
                  <Col>
                    <Form.Label>Bairro</Form.Label>
                    <Form.Control placeholder="Bairro" {...register('address_Neighborhood')} />
                  </Col>
                  <Col>
                    <Form.Label>Cidade</Form.Label>
                    <Form.Control placeholder="Cidade" {...register('address_City')} />
                  </Col>
                  <Col>
                    <Form.Label>Estadp</Form.Label>
                    <Form.Select aria-label="UF" {...register('address_State')}>
                      <option>Selecione a UF</option>
                      {EstadosBrasil.map((item) => {
                        return (
                          <option key={item.sigla} value={item.sigla}>
                            {item.nome}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                </Row>
              </div>
            </div>
            <div>
              <div className="title">
                <IoPersonCircleOutline />
                <h6>Gestor administrador da clínica</h6>
              </div>
              <div>
                <Row className="row-field">
                  <Col>
                    <Form.Label>Nome *</Form.Label>
                    <Form.Control placeholder="Nome *" {...register('responsible_Name')} />
                  </Col>
                </Row>
                <Row className="row-field">
                  <Col>
                    <Form.Label>E-mail *</Form.Label>
                    <Form.Control placeholder="E-mail *" {...register('responsible_Email')} />
                  </Col>
                  <Col>
                    <Form.Label>Celular</Form.Label>
                    <Form.Control placeholder="Celular" {...register('responsible_Document_RG')} />
                  </Col>
                </Row>
              </div>
            </div>
            <div>
              <div className="title">
                <AiOutlineFileProtect />
                <h6>Termos e condições de uso</h6>
              </div>
              <div>
                <Row className="row-field">
                  <Col xs={2}>
                    <Button className="btn-salvar-clinica">
                      <FaEye width={30} style={{ paddingRight: '5px' }} />
                      Ler os termos
                    </Button>
                  </Col>
                  <Col>
                    <Form.Check type="checkbox" label="Li e concordo*" />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Form>
      </Container>
    </CreateClinicPageStyled>
  );
};

export default CreateClinicPage;
