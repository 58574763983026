import { AxiosResponse } from 'axios';
import { HttpRequestContract } from '../../../domain/contract/HttpContract';
import AxiosApi from '../../api/AxiosApi';

export async function AuthCall(request: HttpRequestContract) {
  let axiosResponse: AxiosResponse;

  try {
    axiosResponse = await AxiosApi.request({
      url: request.url,
      method: request.method,
      data: request.body,
      headers: request.headers,
    });
  } catch (error: any) {
    axiosResponse = error;
  }

  return {
    statusCode: axiosResponse.status,
    headers: axiosResponse.headers,
    body: axiosResponse.data,
  };
}
