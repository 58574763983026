import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseLoader } from '../../../presentation/hook/LoaderHook';

import { PaginationType } from '../../contract/Pagination';
import { PrivateRoutes } from '../../../main/constant/RouteConstant';
import { ProfessionalUseCase } from '../../../data/useCase/ProfessionalUseCase';
import ListProfessionalPage from '../../../presentation/page/listProfessional/ListProfessionalPage';

import { InputsSearchProfessional } from '../../../presentation/page/listProfessional/ListProfessionalPageType';
import { UseAlert } from '../../../presentation/hook/AlertHook';

/**
 * ListProfessionalPageDomain - regra de negocio de listagem profissionais
 * @constructor
 */
export const ListProfessionalPageDomain: React.FC = () => {
  /**
   * Alertas
   */
  const { showAlert } = UseAlert();

  /**
   * loading
   */
  const { setLoad } = UseLoader();

  let PAGE = 0;
  let PAGE_SIZE = 5;
  let STATUS = 'Ativos';

  /**
   * endpoint
   */
  const ENDPOINT_PROFESSIONAL = process.env.REACT_APP_END_POINT_PROFESSIONAL ?? '';

  /**
   * navegacao da url
   */
  let navigate = useNavigate();

  /**
   * model
   */
  const [list, setList] = useState<[]>([]);

  /**
   * paginacao
   */
  const [pagination, setPagination] = useState<PaginationType | null>(null);

  /**
   * add
   */
  const add = () => {
    navigate(PrivateRoutes.ADD_PROFESSIONAL);
  };

  /**
   * getList
   */
  const getList = async () => {
    setLoad(true);
    await new ProfessionalUseCase(`${ENDPOINT_PROFESSIONAL}/?page=${PAGE}&pageSize=${PAGE_SIZE}&status=${STATUS}`)
      .Get()
      .then((response: any) => {
        setList(response.body.items);
        setPagination({
          pageIndex: response.body.pageIndex,
          pageSize: response.body.pageSize,
          totalCount: response.body.totalCount,
          totalPages: response.body.totalPages,
          hasPreviousPage: response.body.hasPreviousPage,
          hasNextPage: response.body.hasNextPage,
          onClickItem: handleClickPagination,
        });
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });
    setLoad(false);
  };

  /**
   * remove
   * @param id
   */
  const remove = async (id: number) => {
    setLoad(true);
    await new ProfessionalUseCase(`${ENDPOINT_PROFESSIONAL}/${id}`)
      .Delete()
      .then((response) => {
        showAlert({
          show: true,
          content: 'Operação realizada com sucesso',
          color: 'success',
          time: 2000,
        });
        console.log('RESPONSE: ', response);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });

    await getList();
    setLoad(false);
  };

  /**
   * toggle
   * @param id
   */
  const toggle = async (id: number) => {
    setLoad(true);
    await new ProfessionalUseCase(`${ENDPOINT_PROFESSIONAL}/${id}/reactivate`)
      .Put()
      .then((response) => {
        showAlert({
          show: true,
          content: 'Operação realizada com sucesso',
          color: 'success',
          time: 2000,
        });
        console.log('RESPONSE: ', response);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });

    await getList();
    setLoad(false);
  };

  /**
   * edit
   * @param id
   */
  const edit = (id: number) => {
    navigate(`${PrivateRoutes.ADD_PROFESSIONAL}/${id}`);
  };

  /**
   * searchProfessional
   * @param params
   */
  const searchProfessional = async (params: InputsSearchProfessional) => {
    STATUS = params.status ?? 'Ativos';
    setLoad(true);
    await new ProfessionalUseCase(`${ENDPOINT_PROFESSIONAL}/?page=${PAGE}&pageSize=${PAGE_SIZE}&name=${params.nome}&status=${params.status ?? 'Ativos'}`)
      .Get()
      .then((response) => {
        setList(response.body.items);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });
    setLoad(false);
  };

  /**
   * handleClickPagination
   * @param id
   */
  const handleClickPagination = async (id: number) => {
    PAGE = id;
    await getList();
  };

  /**
   *
   */
  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <ListProfessionalPage add={add} list={list} remove={remove} edit={edit} search={searchProfessional} pagination={pagination} toggle={toggle} />
    </>
  );
};
