import { CrudCall } from '../../infra/call/crud/CrudCall';
import { RequestApi } from '../../domain/contract/HttpContract';

/**
 * ProfessionalUseCase
 * responsável pelas requisições de profisionais
 */
export class ProfessionalUseCase {
  constructor(private readonly endPoint: string) {
    this.endPoint = endPoint;
  }

  /**
   * Get
   * @param request
   * @constructor
   */
  async Get(request?: RequestApi) {
    return await CrudCall({
      url: this.endPoint,
      method: 'GET',
      body: request?.data,
      headers: request?.headers,
    }).then((response) => response);
  }

  /**
   * Post
   * @param request
   * @constructor
   */
  async Post(request?: RequestApi) {
    return await CrudCall({
      url: this.endPoint,
      method: 'POST',
      body: request?.data,
      headers: request?.headers,
    }).then((response) => response);
  }

  /**
   * Put
   * @param request
   * @constructor
   */
  async Put(request?: RequestApi) {
    return await CrudCall({
      url: this.endPoint,
      method: 'PUT',
      body: request?.data,
      headers: request?.headers,
    }).then((response) => response);
  }

  /**
   * Delete
   * @param request
   * @constructor
   */
  async Delete(request?: RequestApi) {
    return await CrudCall({
      url: this.endPoint,
      method: 'DELETE',
      body: request?.data,
      headers: request?.headers,
    }).then((response) => response);
  }
}
