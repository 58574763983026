import { createContext, ReactNode, useState } from 'react';

export const SidebarContext = createContext({} as SidebarContextContextType);

export type ProviderPropsType = { children: ReactNode };
export type SidebarContextContextType = {
  open: boolean;
  setOpen: (status: boolean) => void;
};

/**
 * SidebarProvider - provedor da barra lateral do menu
 * @param props
 * @constructor
 */
export function SidebarProvider(props: ProviderPropsType) {
  const [open, setOpen] = useState<boolean>(true);
  return <SidebarContext.Provider value={{ open, setOpen }}>{props.children}</SidebarContext.Provider>;
}
