import styled from 'styled-components';

export const AddPatientPageStyled = styled.div`
  background-color: #f4f3f3;
  padding-top: 3%;

  .title-page {
    padding-top: 1%;
  }

  .row-field-filter {
    padding-top: 1%;
  }

  .table-page {
    padding-top: 3%;
  }

  .container-field {
    padding-bottom: 2%;
    .title {
      padding: 2% 0 0 0;
      display: flex;

      h6 {
        padding-left: 10px;
      }
    }

    .row-field {
      padding: 5px 0px 0px 0px;
    }
  }

  .form-control:focus {
    border-color: #9459d0;
    box-shadow: 0 0 0 0.25rem rgba(128, 0, 128, 0.25);
  }

  .form-select:focus {
    border-color: #9459d0;
    box-shadow: 0 0 0 0.25rem rgba(128, 0, 128, 0.25);
  }
`;
