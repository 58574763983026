import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PrivateRoutes } from '../../../main/constant/RouteConstant';
import { ProfessionalUseCase } from '../../../data/useCase/ProfessionalUseCase';
import AddProfessionalPage from '../../../presentation/page/addProfessional/AddProfessionalPage';

import { AddProfessional, EditProfessional } from '../../../presentation/page/addProfessional/AddProfessionalPageType';
import { PaginationType } from '../../contract/Pagination';
import { UseAlert } from '../../../presentation/hook/AlertHook';

/**
 * AddProfessionalPageDomain - regra de negocio de profissional
 * @constructor
 */
export const AddProfessionalPageDomain: React.FC = () => {
  /**
   * Alertas
   */
  const { showAlert } = UseAlert();

  let PAGE = 0;
  let PAGE_SIZE = 10;

  /**
   * endpoint
   */
  const ENDPOINT_PROFESSIONAL = process.env.REACT_APP_END_POINT_PROFESSIONAL ?? '';

  /**
   * model
   */
  const [professional, setProfessional] = useState<null>(null);
  const [professionalSpecialties, setProfessionalSpecialties] = useState<[]>([]);
  const [specialtiesList, setSpecialties] = useState<[]>([]);

  /**
   * paginacao
   */
  const [pagination, setPagination] = useState<PaginationType | null>(null);

  /**
   * navegacao da url
   */
  let navigate = useNavigate();

  /**
   * parametros da url
   */
  const urlParams = useParams();

  /**
   * add
   * @param params
   */
  const add = async (params: AddProfessional) => {
    await new ProfessionalUseCase(`${ENDPOINT_PROFESSIONAL}/`)
      .Post({ data: params })
      .then((response) => {
        showAlert({
          show: true,
          content: 'Operação realizada com sucesso',
          color: 'success',
          time: 2000,
        });
        console.log('RESPONSE: ', response);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });

    comeBack();
  };

  /**
   * edit
   * @param params
   */
  const edit = async (params: EditProfessional) => {
    await new ProfessionalUseCase(`${ENDPOINT_PROFESSIONAL}/${params.id}`)
      .Put({ data: params })
      .then((response) => {
        showAlert({
          show: true,
          content: 'Operação realizada com sucesso',
          color: 'success',
          time: 2000,
        });
        console.log('RESPONSE: ', response);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });

    comeBack();
  };

  /**
   * comeBack - voltar
   */
  const comeBack = () => {
    navigate(PrivateRoutes.LIST_PROFESSIONAL);
  };

  /**
   * getProfessional - busca profissional
   * @param id
   */
  const getProfessional = async (id: number) => {
    await new ProfessionalUseCase(`${ENDPOINT_PROFESSIONAL}/${id}`)
      .Get()
      .then((response) => {
        setProfessional(response.body);
        setProfessionalSpecialties(response.body.specialties);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });
  };

  /**
   * getProfessional - busca profissional
   * @param id
   */
  const getspecialties = async () => {
    await new ProfessionalUseCase(`${ENDPOINT_PROFESSIONAL}/specialties?page=${PAGE}&pageSize=${PAGE_SIZE}`)
      .Get()
      .then((response) => {
        setSpecialties(response.body.items);
        setPagination({
          pageIndex: response.body.pageIndex,
          pageSize: response.body.pageSize,
          totalCount: response.body.totalCount,
          totalPages: response.body.totalPages,
          hasPreviousPage: response.body.hasPreviousPage,
          hasNextPage: response.body.hasNextPage,
          onClickItem: handleClickPagination,
        });
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });
  };

  /**
   * handleClickPagination
   * @param id
   */
  const handleClickPagination = async (id: number) => {
    PAGE = id;
    await getspecialties();
  };

  /**
   * inicia preenchendo o profissional caso tem um id na url
   */
  const init = async () => {
    if (urlParams.id) {
      await getProfessional(Number(urlParams.id));
    }
    await getspecialties();
  };

  /**
   * inicio
   */
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <AddProfessionalPage
        add={add}
        comeBack={comeBack}
        professional={professional}
        getProfessional={getProfessional}
        edit={edit}
        professionalSpecialties={professionalSpecialties}
        specialtiesList={specialtiesList}
        specialtiesPagination={pagination}
      />
    </>
  );
};
