/**
 *
 * ALERT / ALERT MODAL
 *
 */
import { UseAlert } from '../../hook/AlertHook';
import React, { useMemo } from 'react';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

const AlertComponent = () => {
  const { messageAlert } = UseAlert();

  // const AlertMemo = useMemo(() => Alert(), [messageAlert, showAlert]);

  // function Alert() {
  //   if (messageAlert?.show) {
  //     return (
  //
  //     );
  //   } else {
  //     return <></>;
  //   }
  // }

  return (
    <div>
      {messageAlert?.show && (
        <div style={{ display: 'none' }}>
          {Store.addNotification({
            title: '',
            message: messageAlert.content,
            type: messageAlert.color,
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: messageAlert.time ? messageAlert.time : 5000,
              onScreen: true,
              showIcon: true,
            },
          })}
        </div>
      )}
    </div>
  );
};

export default AlertComponent;
