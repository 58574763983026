import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { UseAuth } from '../../presentation/hook/AuthHook';

/**
 * Regra das rotas, verifica se o usuario esta logado, indo ao localstorage
 * @constructor
 */
export function AppRoutes() {
  const { isLogged } = UseAuth();
  return isLogged ? <PrivateRoute /> : <PublicRoute />;
}

export default AppRoutes;
