import { useNavigate } from 'react-router-dom';

import ChangePasswordPage from '../../../presentation/page/changePassword/ChangePasswordPage';

import { PublicRoutes } from '../../../main/constant/RouteConstant';
import { ForgotPasswordUseCase } from '../../../data/useCase/ForgotPasswordUseCase';
import { UseAlert } from '../../../presentation/hook/AlertHook';

/**
 * ChangePasswordPageDomain - regra de negocio de troca senha
 * @constructor
 */
export const ChangePasswordPageDomain: React.FC = () => {
  /**
   * Alertas
   */
  const { showAlert } = UseAlert();

  /**
   * navegacao da url
   */
  const navigate = useNavigate();

  const ENDPOINT_FORGOT_PASSWORD = process.env.REACT_APP_END_POINT_FORGOT_PASSWORD ?? '';

  const sendPassword = async (params: { login: string }) => {
    const loginFormData = new FormData();
    loginFormData.append('username', params.login);
    await new ForgotPasswordUseCase(`${ENDPOINT_FORGOT_PASSWORD}`)
      .Post({
        data: {
          login: 'romualdo.krameck@lumegrupo.com.br',
        },
      })
      .then((response) => {
        if (response.statusCode === 200) {
          navigate(PublicRoutes.LOGIN);
        }

        showAlert({
          show: true,
          content: 'Operação realizada com sucesso',
          color: 'success',
          time: 2000,
        });
        console.log('RESPONSE: ', response);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });
  };

  return (
    <>
      <ChangePasswordPage sendPassword={sendPassword} />
    </>
  );
};
