import { AxiosResponse } from 'axios';
import AxiosApi from '../../api/AxiosApi';

import { getLocalStorage } from '../../cache/LocalstorageCache';
import { HttpRequestContract } from '../../../domain/contract/HttpContract';
import { HttpStatusCodeEnum } from '../../../domain/enums/HttpStatusCodeEnum';

export async function CrudCall(request: HttpRequestContract) {
  let axiosResponse: AxiosResponse;

  const KEY_TOKEN_USER_LOGIN = process.env.LOCALSTORAGE_TOKEN_USER_LOGIN ?? '';
  const tokenApplication = getLocalStorage(KEY_TOKEN_USER_LOGIN);

  AxiosApi.defaults.headers.common = {
    Authorization: `Bearer ${tokenApplication.auth}`,
  };

  try {
    axiosResponse = await AxiosApi.request({
      url: request.url,
      method: request.method,
      data: request.body,
      headers: request.headers,
    });
  } catch (error: any) {
    if (error.request.status === HttpStatusCodeEnum.unauthorized) {
      localStorage.clear();
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }

    console.error(error);
    axiosResponse = error;
  }

  return {
    statusCode: axiosResponse.status,
    headers: axiosResponse.headers,
    body: axiosResponse.data,
  };
}
