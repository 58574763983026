export const maskUtils = {
  celular(value: string) {
    // Formato: (99) 99999-9999
    return value
      .replace(/\D/g, '') // Remove caracteres não numéricos
      .replace(/^(\d{2})(\d)/g, '($1) $2') // Adiciona parênteses nos dois primeiros dígitos
      .replace(/(\d{5})(\d{1,4})/, '$1-$2') // Adiciona o hífen depois dos primeiros cinco dígitos
      .replace(/(-\d{4})\d+?$/, '$1'); // Limita o número total de dígitos a 11
  },

  telefone(value: string) {
    // Formato: (041) 999449784
    return value
      .replace(/\D/g, '') // Remove caracteres não numéricos
      .replace(/^(\d{3})(\d)/, '($1) $2') // Adiciona parênteses nos três primeiros dígitos
      .replace(/(\d{5})(\d{4})/, '$1$2') // Formato direto sem hífen
      .replace(/(\d{9})\d+?$/, '$1'); // Limita o número total de dígitos a 12
  },

  cpf(value: string) {
    // Formato: 999.999.999-99
    return value
      .replace(/\D/g, '') // Remove caracteres não numéricos
      .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona o ponto após três dígitos
      .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona o segundo ponto
      .replace(/(\d{3})(\d{1,2})/, '$1-$2') // Adiciona o hífen antes dos dois últimos dígitos
      .replace(/(-\d{2})\d+?$/, '$1'); // Limita o número total de dígitos a 11
  },

  cnpj(value: string) {
    // Formato: 99.999.999/9999-99
    return value
      .replace(/\D/g, '') // Remove caracteres não numéricos
      .replace(/(\d{2})(\d)/, '$1.$2') // Adiciona o ponto após os dois primeiros dígitos
      .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona o segundo ponto
      .replace(/(\d{3})(\d)/, '$1/$2') // Adiciona a barra após os primeiros seis dígitos
      .replace(/(\d{4})(\d{1,2})/, '$1-$2') // Adiciona o hífen antes dos dois últimos dígitos
      .replace(/(-\d{2})\d+?$/, '$1'); // Limita o número total de dígitos a 14
  },

  cep(value: string) {
    // Formato: 99999-999
    return value
      .replace(/\D/g, '') // Remove caracteres não numéricos
      .replace(/^(\d{5})(\d{1,3})/, '$1-$2') // Adiciona o hífen depois dos primeiros cinco dígitos
      .replace(/(-\d{3})\d+?$/, '$1'); // Limita o número total de dígitos a 8
  },
};
