import React from 'react';
import { useForm } from 'react-hook-form';

import { ListProfessionalPageStyled } from './ListProfessionalPageStyled';
import PaginationComponent from '../../component/pagination/PaginationComponent';
import { Badge, Button, Col, Container, Form, Pagination, Row, Table } from 'react-bootstrap';
import ContainerSideBarComponent from '../../component/container/ContainerSidebarComponent';

import { FaRegEye } from 'react-icons/fa6';
import { FaPlus, FaSearch, FaTrashAlt } from 'react-icons/fa';

import { InputsSearchProfessional, PropsListProfessional } from './ListProfessionalPageType';

/**
 * ListProfessionalPage
 * @param add
 * @param list
 * @param remove
 * @param edit
 * @param search
 * @param pagination
 * @param toggle
 * @constructor
 */
const ListProfessionalPage: React.FC<PropsListProfessional> = ({ add, list, remove, edit, search, pagination, toggle }: PropsListProfessional) => {
  /**
   * useForm
   */
  const { register, getValues, setValue } = useForm<InputsSearchProfessional>();

  return (
    <ContainerSideBarComponent>
      <ListProfessionalPageStyled>
        <Container fluid className="title-page">
          <h3>Profissionais</h3>
        </Container>

        <Container fluid>
          <Row className="row-field-filter">
            <Col xs={8}>
              <Form.Control type="text" placeholder="Pesquise um profissional pelo nome" {...register('nome')} />
            </Col>
            <Col xs={2}>
              <Form.Check inline type="radio" label="Ativos" value="Ativos" {...register('status')} />
              <Form.Check inline type="radio" label="Inativos" value="Inativos" {...register('status')} />
            </Col>
            <Col xs={2} className="filter-btn">
              <Button style={{ float: 'right' }} onClick={add}>
                <FaPlus />
                <span>Novo</span>
              </Button>
              <Button
                size="sm"
                variant="outline-primary"
                style={{ float: 'right', marginRight: '5px' }}
                onClick={() =>
                  search({
                    nome: getValues('nome'),
                    status: getValues('status'),
                  })
                }>
                <FaSearch />
                <span>Pesquisar</span>
              </Button>
            </Col>
          </Row>
        </Container>
        <Container fluid className="table-page">
          <Table striped={true} bordered={false} borderless={false} hover>
            <thead>
              <tr>
                <th>CRO</th>
                <th>Nome</th>
                <th>Celular</th>
                <th>Email</th>
                <th>Especialidade</th>
                <th>Perfil</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{item.boardRegistration}</td>
                    <td>{item.name}</td>
                    <td>{item.cellPhone}</td>
                    <td>{item.email}</td>
                    <td width={'20%'}>
                      {item.specialties !== null &&
                        item.specialties.map((itemspecialties: string) => {
                          return (
                            <Badge style={{ marginRight: '2px' }} key={itemspecialties} bg="secondary">
                              {itemspecialties}
                            </Badge>
                          );
                        })}
                    </td>
                    <td>{item.profileDescription}</td>
                    <td>
                      <Row className="">
                        <Col className="table-btn">
                          <Button size="sm" variant="danger" style={{ float: 'right', marginRight: '5px' }} onClick={() => remove(item.id)}>
                            <FaTrashAlt />
                            <span>Deletar</span>
                          </Button>
                          <Button size="sm" variant="outline-primary" style={{ float: 'right' }} onClick={() => edit(item.id)}>
                            <FaRegEye />
                            <span>Visualizar</span>
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <PaginationComponent
            totalPages={pagination?.totalPages}
            pageIndex={pagination?.pageIndex}
            onClickItem={pagination?.onClickItem}
            hasPreviousPage={pagination?.hasPreviousPage}
            hasNextPage={pagination?.hasNextPage}
          />
        </Container>
      </ListProfessionalPageStyled>
    </ContainerSideBarComponent>
  );
};

export default ListProfessionalPage;
