import ListAppointmentPage from '../../../presentation/page/listAppointment/ListAppointmentPage';
import { UseLoader } from '../../../presentation/hook/LoaderHook';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CalendarioAnos, CalendarioMeses } from '../../../main/constant/Calendario';
import { AddAppointment, EditAppointment, InputsSearchAppointment } from '../../../presentation/page/listAppointment/ListAppointmentPageType';
import { AppointmentUseCase } from '../../../data/useCase/AppointmentUseCase';
import { ProfessionalUseCase } from '../../../data/useCase/ProfessionalUseCase';
import { PatientUseCase } from '../../../data/useCase/PatientUseCase';
import { UseAlert } from '../../../presentation/hook/AlertHook';

export const ListAppointmentPageDomain: React.FC = () => {
  /**
   * Alertas
   */
  const { showAlert } = UseAlert();

  /**
   * loading
   */
  const { setLoad } = UseLoader();

  /**
   * endpoint
   */
  const ENDPOINT_APPOINTMENTS = process.env.REACT_APP_END_POINT_APPOINTMENTS ?? '';
  const ENDPOINT_PROFESSIONAL = process.env.REACT_APP_END_POINT_PROFESSIONAL ?? '';
  const ENDPOINT_PATIENT = process.env.REACT_APP_END_POINT_PATIENT ?? '';

  /**
   * navegacao da url
   */
  let navigate = useNavigate();

  /**
   * model
   */
  const [list, setList] = useState<[]>([]);
  const [listProfessional, setListProfessional] = useState<[]>([]);
  const [listPatient, setListPatient] = useState<[]>([]);

  /**
   * model
   */
  const [appointment, setAppointment] = useState<null>(null);

  /**
   * model data do calendario
   */
  const [defaultDate, setDefaultDate] = useState(new Date());

  /**
   * getCurrentYearAndMonth - retorna ano e mes atual
   */
  const getCurrentYearAndMonth = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    return { year, month };
  };

  /**
   * getList
   */
  const getList = async () => {
    setLoad(true);

    const { year, month } = getCurrentYearAndMonth(); // Obtém o ano e mês atuais

    await new AppointmentUseCase(`${ENDPOINT_APPOINTMENTS}/${year}/${month}`)
      .Get()
      .then((response: any) => {
        setList(
          response.body.map((item: any) => {
            return {
              start: item.start,
              end: item.end,
              title: `${item.observation} - ${item.professionalName}`,
              id: item.id,
            };
          })
        );
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });
    setLoad(false);
  };

  /**
   * searchPatient
   * @param params
   */
  const searchAppointment = async (searchParams: InputsSearchAppointment) => {
    const initialDate = new Date(Number(searchParams.ano), Number(searchParams.mes) - 1, 1);
    let adjustedMonth = searchParams.mes;
    let adjustedYear = searchParams.ano;

    if (searchParams.sequencia) {
      let adjustedDate = new Date(initialDate); // Cria uma cópia da data inicial

      if (searchParams.sequencia === 'anterior') {
        adjustedDate.setMonth(adjustedDate.getMonth() - 1); // Mês anterior
      }

      if (searchParams.sequencia === 'proximo') {
        adjustedDate.setMonth(adjustedDate.getMonth() + 1); // Mês posterior
      }

      adjustedMonth = String(adjustedDate.getMonth() + 1); // Mês ajustado (adicionar +1, pois começa em 0)
      adjustedYear = String(adjustedDate.getFullYear()); // Ano ajustado
    }

    const finalAdjustedDate = new Date(Number(adjustedYear), Number(adjustedMonth) - 1, 1);
    setLoad(true);
    await new AppointmentUseCase(`${ENDPOINT_APPOINTMENTS}/${adjustedYear}/${adjustedMonth}`)
      .Get()
      .then((response) => {
        setList(
          response.body.map((appointment: any) => {
            return {
              start: appointment.start,
              end: appointment.end,
              title: `${appointment.observation} - ${appointment.professionalName}`,
              id: appointment.id,
            };
          })
        );

        setDefaultDate(finalAdjustedDate);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });

    setLoad(false);
  };

  /**
   * add
   * @param params
   */
  const add = async (params: AddAppointment) => {
    setLoad(true);

    const findProfessional: any = listProfessional.filter((item: any) => item.id == params.professionalId);
    const findPatient: any = listPatient.filter((item: any) => item.id == params.patientId);

    const modelRequest = {
      status: params.status === '' ? 'A' : params.status,
      patientId: params.patientId,
      patientName: findPatient.length > 0 ? findPatient[0].name : '',
      professionalId: params.professionalId,
      professionalName: findProfessional.length > 0 ? findProfessional[0].name : '',
      start: params.start,
      end: params.end,
      chair: Number(params.chair),
      observation: params.observation,
    };

    await new AppointmentUseCase(`${ENDPOINT_APPOINTMENTS}`)
      .Post({ data: modelRequest })
      .then((response) => {
        showAlert({
          show: true,
          content: 'Operação realizada com sucesso',
          color: 'success',
          time: 2000,
        });
        console.log('RESPONSE: ', response);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });

    await getList();

    setLoad(false);
  };

  /**
   * edit
   * @param params
   */
  const edit = async (params: EditAppointment) => {
    setLoad(true);

    const findProfessional: any = listProfessional.filter((item: any) => item.id == params.professionalId);
    const findPatient: any = listPatient.filter((item: any) => item.id == params.patientId);

    const modelRequest = {
      status: params.status === '' ? 'A' : params.status,
      patientId: params.patientId,
      patientName: findPatient.length > 0 ? findPatient[0].name : '',
      professionalId: params.professionalId,
      professionalName: findProfessional.length > 0 ? findProfessional[0].name : '',
      start: params.start,
      end: params.end,
      chair: Number(params.chair),
      observation: params.observation,
    };

    await new AppointmentUseCase(`${ENDPOINT_APPOINTMENTS}/${params.id}`)
      .Put({ data: modelRequest })
      .then((response) => {
        showAlert({
          show: true,
          content: 'Operação realizada com sucesso',
          color: 'success',
          time: 2000,
        });
        console.log('RESPONSE: ', response);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });

    await getList();

    setLoad(false);
  };

  /**
   * getPatient
   * @param id
   */
  const getAppointment = async (id: number) => {
    setLoad(true);

    if (id !== undefined || id !== null) {
      await new AppointmentUseCase(`${ENDPOINT_APPOINTMENTS}/${id}`)
        .Get()
        .then((response) => {
          setAppointment(response.body);
        })
        .catch((error) => {
          showAlert({
            show: true,
            content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
            color: 'danger',
            time: 2000,
          });
          console.error('ERRO: ', error);
        });
    }
    setLoad(false);
  };

  /**
   * getListProfessional
   */
  const getListProfessional = async () => {
    setLoad(true);
    await new ProfessionalUseCase(`${ENDPOINT_PROFESSIONAL}/enumeration`)
      .Get()
      .then((response: any) => {
        setListProfessional(response.body);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });
    setLoad(false);
  };

  /**
   * getListPatient
   */
  const getListPatient = async () => {
    setLoad(true);
    await new PatientUseCase(`${ENDPOINT_PATIENT}/enumeration`)
      .Get()
      .then((response: any) => {
        setListPatient(response.body);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });
    setLoad(false);
  };

  /**
   *
   */
  useEffect(() => {
    getList();
    getListProfessional();
    getListPatient();
  }, []);

  return (
    <>
      <ListAppointmentPage
        listAppointment={list}
        add={add}
        search={searchAppointment}
        meses={CalendarioMeses}
        anos={CalendarioAnos}
        defaultDate={defaultDate}
        getAppointment={getAppointment}
        appointment={appointment}
        edit={edit}
        listPatient={listPatient}
        listProfessional={listProfessional}
      />
    </>
  );
};
