import styled from 'styled-components';

export const LoginPageStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;

  .imagen {
  }

  .form-login {
    padding: 2% 5% 10%;
    justify-items: center;
    align-items: center;
    width: 35%;

    .input-email {
      background-color: transparent;
      color: #f4f3f3;
    }

    .input-senha {
      background-color: transparent;
      color: #f4f3f3;
    }

    .label-email {
      color: #f4f3f3;
    }

    .label-senha {
      color: #f4f3f3;
    }

    .btn-entrar {
      width: 100%;
      margin-top: 10%;
    }

    .link-esqueci-senha {
      width: 100%;
      text-decoration: none;
      color: #f4f3f3;
    }
  }

  .btn-footer {
    width: 35%;
    padding-top: 2%;

    padding-left: 5%;
    padding-right: 5%;

    .btn-nova-clinica {
      width: 100%;
      color: #f4f3f3;
    }
  }

  .text-footer {
    color: #f4f3f3;
    padding-top: 30px;
    text-align: center;
    font-size: small;
  }

  @media (max-width: 768px) {
    .form-login {
      width: 100%;
    }

    .btn-footer {
      width: 100%;
    }
  }
`;
