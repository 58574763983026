import React, { useState } from 'react';

import { Image } from 'react-bootstrap';

import './SidebarComponent.css';

import { useNavigate } from 'react-router-dom';

import { UseSidebar } from '../../hook/SidebarHook';
import { PrivateRoutes } from '../../../main/constant/RouteConstant';

import logo from '../../images/icone_logo_idente.png';

import { FaPowerOff } from 'react-icons/fa6';
import { GoSidebarExpand } from 'react-icons/go';
import { FaRegCalendarAlt, FaLock, FaRegIdCard, FaSlidersH, FaUserMd } from 'react-icons/fa';

/**
 * SidebarComponent - componente de sidebar
 * @constructor
 */
const SidebarComponent: React.FC = () => {
  /**
   * isOpen - verifica se esta aberta
   */
  const [isOpen, setIsOpen] = useState(true);

  /**
   * verifica globalmente se esta aberta
   */
  const { setOpen } = UseSidebar();

  /**
   * navega na url
   */
  let navigate = useNavigate();

  /**
   * toggleSidebar
   */
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    setOpen(!isOpen);
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <div className="sidebar-header">
        {isOpen && (
          <>
            <div className="menu-img" onClick={toggleSidebar}>
              <Image src={logo} width={50} style={{ color: '#f4f3f3' }} />
            </div>
            <div className="menu-btn" onClick={toggleSidebar}>
              <GoSidebarExpand />
            </div>
          </>
        )}

        {!isOpen && (
          <>
            <div className="menu-btn" onClick={toggleSidebar}>
              <GoSidebarExpand />
            </div>
          </>
        )}
      </div>
      <div className="sidebar-content">
        <a href={PrivateRoutes.LIST_APPOINTMENT} className="sidebar-link">
          <FaRegCalendarAlt className="icon-lg" />
          {isOpen && <span>Agendamento</span>}
        </a>
        <a href={PrivateRoutes.LIST_PATIENT} className="sidebar-link">
          <FaRegIdCard className="icon-lg" />
          {isOpen && <span>Pacientes</span>}
        </a>
        <a href={PrivateRoutes.LIST_PROFESSIONAL} className="sidebar-link">
          <FaUserMd className="icon-lg" />
          {isOpen && <span>Profissionais</span>}
        </a>
      </div>
      <hr className="divider" />
      <div className="sidebar-bottom">
        <a href={PrivateRoutes.EDIT_CLINIC} className="sidebar-link">
          <FaSlidersH className="icon-lg" />
          {isOpen && <span>Clínica</span>}
        </a>
        <a href={PrivateRoutes.CHANGE_PASSWORD} className="sidebar-link">
          <FaLock className="icon-lg" />
          {isOpen && <span>Trocar senha</span>}
        </a>
        <a
          href="/"
          className="sidebar-link"
          onClick={() => {
            localStorage.clear();
            navigate('/');
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          }}>
          <FaPowerOff className="icon-lg" />
          {isOpen && <span>Logout</span>}
        </a>
      </div>
    </div>
  );
};

export default SidebarComponent;
