import styled from 'styled-components';

export const GlobalStyled = styled.div`
  background: #f0f0f0;
  min-height: 100vh;

  .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #9459d0;
    --bs-btn-border-color: #9459d0;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #824db6;
    --bs-btn-hover-border-color: #7546a6;
    --bs-btn-focus-shadow-rgb: 148, 89, 208;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #7546a6;
    --bs-btn-active-border-color: #6a3f95;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #9459d0;
    --bs-btn-disabled-border-color: #9459d0;
  }

  .btn-outline-primary {
    --bs-btn-color: #9459d0;
    --bs-btn-border-color: #9459d0;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #9459d0;
    --bs-btn-hover-border-color: #9459d0;
    --bs-btn-focus-shadow-rgb: 148, 89, 208; /* RGB correspondente ao #9459d0 */
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #9459d0;
    --bs-btn-active-border-color: #9459d0;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #9459d0;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #9459d0;
    --bs-gradient: none;
  }
`;
