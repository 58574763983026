import React, { ReactNode } from 'react';
import { LoadingComponentStyled } from './LoadingComponentStyled';
import { UseLoader } from '../../hook/LoaderHook';

interface LoadingComponentProps {
  children: ReactNode;
}
const LoadingComponent: React.FC<LoadingComponentProps> = ({ children }) => {
  const { inLoad } = UseLoader();

  return (
    <>
      {!inLoad && <>{children}</>}
      {inLoad && (
        <LoadingComponentStyled>
          <div className="spinner-border text-secondary" role="status"></div>
        </LoadingComponentStyled>
      )}
    </>
  );
};

export default LoadingComponent;
