/**
 * Rotas sem autenticacao
 */
export enum PublicRoutes {
  LOGIN = '*',
  CREATE_CLINIC = '/nova-clinica',
  FORGOT_PASSWORD = '/esqueci-minha-senha',
}

/**
 * Rotas com autenticacao
 */
export enum PrivateRoutes {
  HOME = '/',
  ADD_PROFESSIONAL = '/adicionar-profissional',
  LIST_PROFESSIONAL = '/listar-profissional',
  ADD_PATIENT = '/adicionar-paciente',
  LIST_PATIENT = '/listar-paciente',
  CHANGE_PASSWORD = '/trocar-senha',
  EDIT_CLINIC = '/editar-clinica',
  LIST_APPOINTMENT = '/listar-apontamentos',
}
