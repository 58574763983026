import styled from 'styled-components';

export const ListAppointmentPageStyled = styled.div`
  background-color: #f4f3f3;
  padding-top: 3%;

  .title-page {
    padding-top: 1%;
  }

  .row-field-filter {
    padding: 2% 4%;

    .filter-btn {
      button {
        display: flex;
        justify-items: center;
        justify-content: space-between;
        align-items: center;
        padding: 3px;
        margin: 5px;
        span {
          padding-left: 5px;
        }
      }
    }
  }

  .calendario {
    padding: 2% 5%;
  }

  .container-field-canvas {
    padding-bottom: 5%;

    .row-field-canvas {
      padding: 5px 0px 0px 0px;
    }
  }
`;

export const CanvasPageStyled = styled.div`
  .container-field-canvas {
    padding-bottom: 1%;

    .row-field-canvas {
      padding: 15px 0px 0px 0px;
    }
  }
`;
