/**
 * setLocalStorege - insere no localstorage
 * @param key
 * @param value
 */
export function setLocalStorege(key: string, value: object): void {
  if (value) localStorage.setItem(key, JSON.stringify(value));
  else localStorage.removeItem(key);
}

/**
 * getLocalStorage - busca do localstorage
 * @param key
 */
export function getLocalStorage(key: string): any {
  return JSON.parse(localStorage.getItem(key)!);
}

/**
 * updateLocalStorege - altera valor no localstorage
 * @param key
 * @param value
 */
export function updateLocalStorege(key: string, value: object): void {
  if (key) {
    localStorage.removeItem(key);
    localStorage.setItem(key, JSON.stringify(value));
  }
}
