import styled from 'styled-components';

export const ListPatientPageStyled = styled.div`
  background-color: #f4f3f3;
  padding-top: 3%;

  .title-page {
    padding-top: 1%;
  }

  .row-field-filter {
    padding-top: 1%;

    .filter-btn {
      display: flex;
      justify-content: end;
      align-items: end;
      button {
        display: flex;
        justify-items: center;
        justify-content: space-between;
        align-items: center;
        padding: 3px;
        margin: 5px;
        span {
          padding-left: 5px;
        }
      }
    }
  }

  .table-page {
    padding-top: 3%;

    .table-btn {
      display: flex;
      justify-items: center;
      justify-content: end;
      align-items: center;

      button {
        display: flex;
        justify-items: center;
        justify-content: space-between;
        align-items: center;
        padding: 3px;
        margin: 5px;
        span {
          padding-left: 5px;
        }
      }

      //div {
      //  display: flex;
      //  justify-items: center;
      //  justify-content: space-between;
      //  align-items: center;
      //  padding: 3px;
      //  margin: 5px;
      //}
    }
  }
`;
