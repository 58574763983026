import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ListPatientPageStyled } from './ListPatientPageStyled';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';

import ContainerSideBarComponent from '../../component/container/ContainerSidebarComponent';
import PaginationComponent from '../../component/pagination/PaginationComponent';

import { FaPlus } from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';
import { FaRegEye } from 'react-icons/fa6';
import { FaTrashAlt } from 'react-icons/fa';
import { FaCircleCheck } from 'react-icons/fa6';
import { FaCircleXmark } from 'react-icons/fa6';

import { InputsSearchPatient, PropsListPatient } from './ListPatientPageType';
import { maskUtils } from '../../../main/helper/maskHelper';
import { Await } from 'react-router';
import AlertComponent from '../../component/alert/AlertComponent';

/**
 * ListPatientPage
 * @param add
 * @param list
 * @param remove
 * @param edit
 * @param search
 * @param pagination
 * @param toggle
 * @constructor
 */
const ListPatientPage: React.FC<PropsListPatient> = ({ add, list, remove, edit, search, pagination, toggle }: PropsListPatient) => {
  /**
   * useForm
   */
  const { register, getValues, setValue, control } = useForm<InputsSearchPatient>();

  return (
    <ContainerSideBarComponent>
      <AlertComponent />
      <ListPatientPageStyled>
        <Container fluid className="title-page">
          <h3>Pacientes</h3>
        </Container>

        <Container fluid>
          <Row className="row-field-filter">
            <Col xs={6}>
              <Form.Label>Pesquise um paciente pelo nome</Form.Label>
              <Form.Control type="text" placeholder="Pesquise um paciente pelo nome" {...register('nome')} />
            </Col>
            <Col xs={2}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Controller
                  name="status"
                  control={control}
                  defaultValue="A"
                  render={({ field }) => (
                    <Form.Select {...field}>
                      <option value="Ativos">Ativos</option>
                      <option value="Inativos">Inativos</option>
                    </Form.Select>
                  )}
                />
              </Form.Group>
            </Col>
            <Col xs={4} className="filter-btn">
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() =>
                  search({
                    nome: getValues('nome'),
                    status: getValues('status'),
                  })
                }>
                <FaSearch />
                <span>Pesquisar</span>
              </Button>
              <Button size="sm" onClick={add}>
                <FaPlus />
                <span>Novo</span>
              </Button>
            </Col>
          </Row>
        </Container>
        <Container fluid className="table-page">
          <Table striped={true} bordered={false} borderless={false} hover>
            <thead>
              <tr>
                <th>Código</th>
                <th>Nome</th>
                <th>Celular</th>
                <th>Email</th>
                <th>Nascimento</th>
                <th>Idade</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{item.patientCode}</td>
                    <td>{item.name}</td>
                    <td>{maskUtils.telefone(item.cellPhone)}</td>
                    <td>{item.email}</td>
                    <td>{item.birthDate}</td>
                    <td>Nascimento</td>
                    <td>
                      <div className="table-btn">
                        <Button size="sm" variant="danger" style={{ float: 'right', marginRight: '5px' }} onClick={() => remove(item.id)}>
                          <FaTrashAlt />
                          <span>Deletar</span>
                        </Button>
                        <Button size="sm" variant="outline-primary" style={{ float: 'right' }} onClick={() => edit(item.id)}>
                          <FaRegEye />
                          <span>Visualizar</span>
                        </Button>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Ativo"
                          checked={item.status === 'A'}
                          onChange={async (e) => {
                            console.log(e.target.checked);
                            if (e.target.checked) {
                              setValue('status', 'Ativos');
                            } else {
                              setValue('status', 'Inativos');
                            }
                            await toggle(item.id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <PaginationComponent
            totalPages={pagination?.totalPages}
            pageIndex={pagination?.pageIndex}
            onClickItem={pagination?.onClickItem}
            hasPreviousPage={pagination?.hasPreviousPage}
            hasNextPage={pagination?.hasNextPage}
          />
        </Container>
      </ListPatientPageStyled>
    </ContainerSideBarComponent>
  );
};

export default ListPatientPage;
