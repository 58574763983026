import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { PrivateRoutes } from '../../../main/constant/RouteConstant';
import { AddPatientPageStyled } from '../addPatient/AddPatientPageStyled';

import { Button, Card, Col, Container, Form, Image, Row } from 'react-bootstrap';
import ContainerSideBarComponent from '../../component/container/ContainerSidebarComponent';

import { TfiSave } from 'react-icons/tfi';
import { FaArrowLeft } from 'react-icons/fa';
import { PiIdentificationCard } from 'react-icons/pi';

interface InputsForgotPassword {
  login: string;
}

type Props = {
  sendPassword: (params: { login: string }) => Promise<any>;
};

/**
 * ChangePasswordPage
 * @param sendPassword
 * @constructor
 */
const ChangePasswordPage: React.FC<Props> = ({ sendPassword }: Props) => {
  /**
   * navega pela url
   */
  let navigate = useNavigate();

  /**
   * useForm
   */
  const { register, getValues } = useForm<InputsForgotPassword>();

  /**
   * handleVoltar
   */
  const handleVoltar = async () => {
    navigate(PrivateRoutes.HOME);
  };

  /**
   * handleSend
   */
  const handleSend = async () => {
    await sendPassword({ login: getValues('login') });
  };

  return (
    <ContainerSideBarComponent>
      <AddPatientPageStyled>
        <Container fluid className="title-page">
          <h3>Trocar Senha</h3>
        </Container>

        <Container fluid>
          <Card>
            <Card.Body>
              <Container fluid>
                <Form>
                  <div className="container-field">
                    <Row className="row-field">
                      <Col>
                        <Button size="sm" style={{ float: 'right' }} onClick={handleSend}>
                          <TfiSave width={30} style={{ paddingRight: '5px' }} />
                          Salvar
                        </Button>
                        <Button size="sm" variant="outline-primary" style={{ float: 'right', marginRight: '5px' }} onClick={handleVoltar}>
                          <FaArrowLeft width={30} style={{ paddingRight: '5px' }} />
                          Voltar
                        </Button>
                      </Col>
                    </Row>
                    <div className="title">
                      <PiIdentificationCard />
                      <h6>Dados</h6>
                    </div>

                    <Row className="row-field">
                      <Col>
                        <Form.Control placeholder="Login" />
                      </Col>
                    </Row>
                    <Row className="row-field">
                      <Col>
                        <Form.Control placeholder="Senha atual *" />
                      </Col>
                    </Row>
                    <Row className="row-field">
                      <Col>
                        <Form.Control placeholder="Nova senha *" />
                      </Col>
                    </Row>
                    <Row className="row-field">
                      <Col>
                        <Form.Control placeholder="Confirme nova senha *" />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Container>
            </Card.Body>
          </Card>
        </Container>
      </AddPatientPageStyled>
    </ContainerSideBarComponent>
  );
};

export default ChangePasswordPage;
