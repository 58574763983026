import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PrivateRoutes } from '../../../main/constant/RouteConstant';
import { PatientUseCase } from '../../../data/useCase/PatientUseCase';
import AddPatientPage from '../../../presentation/page/addPatient/AddPatientPage';

import { AddPatient, EditPatient } from '../../../presentation/page/addPatient/AddPatientPageType';
import { UseAlert } from '../../../presentation/hook/AlertHook';

/**
 * AddPatientPageDomain - regra de negocio de Paciente
 * @constructor
 */
export const AddPatientPageDomain: React.FC = () => {
  /**
   * Alertas
   */
  const { showAlert } = UseAlert();

  /**
   * endpoint
   */
  const ENDPOINT_PATIENT = process.env.REACT_APP_END_POINT_PATIENT ?? '';

  /**
   * model
   */
  const [patient, setPatient] = useState<null>(null);

  /**
   * navegacao da url
   */
  let navigate = useNavigate();

  /**
   * parametros da url
   */
  const urlParams = useParams();

  /**
   * add
   * @param params
   */
  const add = async (params: AddPatient) => {
    await new PatientUseCase(`${ENDPOINT_PATIENT}/`)
      .Post({ data: params })
      .then((response) => {
        showAlert({
          show: true,
          content: 'Operação realizada com sucesso',
          color: 'success',
          time: 2000,
        });
        console.log('RESPONSE: ', response);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });

    comeBack();
  };

  /**
   * edit
   * @param params
   */
  const edit = async (params: EditPatient) => {
    await new PatientUseCase(`${ENDPOINT_PATIENT}/${params.id}`)
      .Put({ data: params })
      .then((response) => {
        showAlert({
          show: true,
          content: 'Operação realizada com sucesso',
          color: 'success',
          time: 2000,
        });
        console.log('RESPONSE: ', response);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });

    comeBack();
  };

  /**
   * comeBack - voltar
   */
  const comeBack = () => {
    navigate(PrivateRoutes.LIST_PATIENT);
  };

  /**
   * getPatient
   * @param id
   */
  const getPatient = async (id: number) => {
    await new PatientUseCase(`${ENDPOINT_PATIENT}/${id}`)
      .Get()
      .then((response) => {
        setPatient(response.body);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });
  };

  /**
   * inicia preenchendo o paciente caso tem um id na url
   */
  const init = async () => {
    if (urlParams.id) {
      await getPatient(Number(urlParams.id));
    }
  };

  /**
   * inicia a tela
   */
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <AddPatientPage add={add} comeBack={comeBack} patient={patient} getPatient={getPatient} edit={edit} />
    </>
  );
};
