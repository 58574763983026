import { createContext, ReactNode, useEffect, useState } from 'react';

import { getLocalStorage } from '../../infra/cache/LocalstorageCache';
import { UserLogged } from '../../domain/contract/UserLogged';

export const AuthContext = createContext({} as AuthContextType);

export type ProviderPropsType = { children: ReactNode };
export type AuthContextType = {
  isLogged: boolean;
  setIsLogged: (status: boolean) => void;
  loggedUserData?: UserLogged;
};

/**
 * AuthProvider - provedor para usuario logado
 * @param props
 * @constructor
 */
export function AuthProvider(props: ProviderPropsType) {
  const TOKEN_USER_LOGIN = process.env.REACT_APP_LOCALSTORAGE_TOKEN_USER_LOGIN;
  const tokenLocalStorage: { token: string } = getLocalStorage(TOKEN_USER_LOGIN ?? '');

  const [isLogged, setIsLogged] = useState<boolean>(tokenLocalStorage ? true : false);

  const [loggedUserData, updateLoggedUserData] = useState<UserLogged>({
    id: 0,
    name: '',
    login: '',
  });

  /** INICIO */
  useEffect(() => {}, []);

  return <AuthContext.Provider value={{ isLogged, setIsLogged, loggedUserData }}>{props.children}</AuthContext.Provider>;
}
