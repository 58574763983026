import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { AddPatientPageStyled } from './AddPatientPageStyled';
import { EstadosBrasil } from '../../../main/constant/UfConstant';
import ContainerSideBarComponent from '../../component/container/ContainerSidebarComponent';

import { TfiSave } from 'react-icons/tfi';
import { FaArrowLeft } from 'react-icons/fa';
import { PiIdentificationCard } from 'react-icons/pi';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { IoLocationOutline, IoPersonCircleOutline } from 'react-icons/io5';

import { EditPatient, InputsPatient, PropsAddPatient } from './AddPatientPageType';

/**
 * AddPatientPage
 * @param add
 * @param comeBack
 * @param patient
 * @param edit
 * @constructor
 */
const AddPatientPage: React.FC<PropsAddPatient> = ({ add, comeBack, patient, edit }: PropsAddPatient) => {
  /** useForm */
  const { register, getValues, setValue } = useForm<InputsPatient>();

  /**
   * handleAdd
   */
  const handleAdd = async () => {
    const model = {
      id: 1,
      status: 'A',
      name: getValues('nome') ?? '',
      code: getValues('codigo') ?? '',
      observation: getValues('observacoes') ?? '',
      birthDate: getValues('dataNascimento') ?? '',
      gender: getValues('sexo') ?? '',
      contact_CellPhone: getValues('celular') ?? '',
      contact_Phone: getValues('segundoTelefone') ?? '',
      contact_Email: getValues('email') ?? '',
      document_CPF: getValues('cpf') ?? '',
      document_RG: getValues('rg') ?? '',
      address_PostalCode: getValues('cep') ?? '',
      address_Street: getValues('endereco') ?? '',
      address_Number: getValues('numero') ?? '',
      address_Complement: getValues('complemento') ?? '',
      address_Neighborhood: getValues('bairro') ?? '',
      address_City: getValues('cidade') ?? '',
      address_State: getValues('uf') ?? '',
      responsible_Name: getValues('nomeResponsavel') ?? '',
      responsible_Document_CPF: getValues('cpfResponsavel') ?? '',
      responsible_Document_RG: getValues('rgResponsavel') ?? '',
      responsible_Contact_Phone: getValues('telefoneResponsavel') ?? '',
    };

    await add(model);
  };

  /**
   * handleEdit
   */
  const handleEdit = async () => {
    const model: EditPatient = {
      id: Number(getValues('id')),
      status: 'null',
      name: getValues('nome') ?? '',
      code: getValues('codigo') ?? '',
      observation: getValues('observacoes') ?? '',
      birthDate: getValues('dataNascimento') ?? '',
      gender: getValues('sexo') ?? '',
      contact_CellPhone: getValues('celular') ?? '',
      contact_Phone: getValues('segundoTelefone') ?? '',
      contact_Email: getValues('email') ?? '',
      document_CPF: getValues('cpf') ?? '',
      document_RG: getValues('rg') ?? '',
      address_PostalCode: getValues('cep') ?? '',
      address_Street: getValues('endereco') ?? '',
      address_Number: getValues('numero') ?? '',
      address_Complement: getValues('complemento') ?? '',
      address_Neighborhood: getValues('bairro') ?? '',
      address_City: getValues('cidade') ?? '',
      address_State: getValues('uf') ?? '',
      responsible_Name: getValues('nomeResponsavel') ?? '',
      responsible_Document_CPF: getValues('cpfResponsavel') ?? '',
      responsible_Document_RG: getValues('rgResponsavel') ?? '',
      responsible_Contact_Phone: getValues('telefoneResponsavel') ?? '',
    };

    await edit(model);
  };

  /**
   * setModel
   * @param model
   */
  const setModel = (model: any) => {
    if (model !== null) {
      setValue('id', model.id);
      setValue('status', model.status);
      setValue('codigo', model.code);
      setValue('nome', model.name);
      setValue('email', model.contact_Email);
      setValue('celular', model.contact_CellPhone);
      setValue('segundoTelefone', model.contact_Phone);
      setValue('cpf', model.document_CPF);
      setValue('rg', model.document_RG);
      setValue('dataNascimento', '14/08/1961');
      setValue('sexo', model.sexo ?? 'M');
      setValue('observacoes', model.observation);
      setValue('cep', model.address_PostalCode);
      setValue('endereco', model.address_Street);
      setValue('numero', model.address_Number);
      setValue('complemento', model.address_Complement);
      setValue('bairro', model.address_Neighborhood);
      setValue('cidade', model.address_City);
      setValue('uf', model.address_State);
      setValue('nomeResponsavel', model.responsible_Name);
      setValue('cpfResponsavel', model.responsible_Document_CPF);
      setValue('rgResponsavel', model.responsible_Document_RG);
      setValue('telefoneResponsavel', model.responsible_Contact_Phone);
    }
  };

  /**
   * inicio
   */
  useEffect(() => {
    setModel(patient);
  }, [patient]);

  return (
    <ContainerSideBarComponent>
      <AddPatientPageStyled>
        <Container fluid className="title-page">
          {patient && <h3>{`Paciente - ${patient.name}`}</h3>}
          {patient === null && <h3>Novo Paciente</h3>}
        </Container>

        <Container fluid>
          <Card>
            <Card.Body>
              <Container fluid>
                <Form>
                  <input type="hidden" id="id" {...register('id')} />
                  <input type="hidden" id="status" {...register('status')} />

                  <div className="container-field">
                    <Row className="row-field">
                      <Col>
                        <Button size="sm" style={{ float: 'right' }} onClick={patient === null ? handleAdd : handleEdit}>
                          <TfiSave width={30} style={{ paddingRight: '5px' }} />
                          Salvar
                        </Button>
                        <Button size="sm" variant="outline-primary" style={{ float: 'right', marginRight: '5px' }} onClick={comeBack}>
                          <FaArrowLeft width={30} style={{ paddingRight: '5px' }} />
                          Voltar
                        </Button>
                      </Col>
                    </Row>
                    <div className="title">
                      <PiIdentificationCard />
                      <h6>Dados Pessoais</h6>
                    </div>

                    <Row className="row-field">
                      <Col>
                        <Form.Label>Código</Form.Label>
                        <Form.Control placeholder="Código" {...register('codigo')} />
                      </Col>
                      <Col>
                        <Form.Label>Nome *</Form.Label>
                        <Form.Control placeholder="Nome *" {...register('nome')} />
                      </Col>
                    </Row>
                    <Row className="row-field">
                      <Col>
                        <Form.Label>Email *</Form.Label>
                        <Form.Control placeholder="Email *" {...register('email')} />
                      </Col>
                      <Col>
                        <Form.Label>Celular *</Form.Label>
                        <Form.Control placeholder="Celular *" {...register('celular')} />
                      </Col>
                      <Col>
                        <Form.Label>Segundo telefone</Form.Label>
                        <Form.Control placeholder="Segundo telefone" {...register('segundoTelefone')} />
                      </Col>
                    </Row>
                    <Row className="row-field">
                      <Col>
                        <Form.Label>CPF</Form.Label>
                        <Form.Control placeholder="CPF" {...register('cpf')} />
                      </Col>
                      <Col>
                        <Form.Label>RG</Form.Label>
                        <Form.Control placeholder="RG" {...register('rg')} />
                      </Col>
                      <Col>
                        <Form.Label>Data nascimento</Form.Label>
                        <Form.Control type="date" placeholder="Data nascimento" {...register('dataNascimento')} />
                      </Col>
                      <Col>
                        <Form.Label>Sexo</Form.Label>
                        <Form.Check inline type="radio" label="Feminino" value="F" {...register('sexo')} />
                        <Form.Check inline type="radio" label="Masculino" value="M" {...register('sexo')} />
                      </Col>
                    </Row>
                    <Row className="row-field">
                      <Col>
                        <Form.Label>Observações</Form.Label>
                        <Form.Control placeholder="Observações" {...register('observacoes')} />
                      </Col>
                    </Row>
                  </div>
                  <div className="container-field">
                    <div className="title">
                      <IoLocationOutline />
                      <h6>Endereço</h6>
                    </div>
                    <div>
                      <Row className="row-field">
                        <Col>
                          <Form.Label>Cep</Form.Label>
                          <Form.Control placeholder="Cep" {...register('cep')} />
                        </Col>
                        <Col>
                          <Form.Label>Endereço</Form.Label>
                          <Form.Control placeholder="Endereço" {...register('endereco')} />
                        </Col>
                      </Row>
                      <Row className="row-field">
                        <Col>
                          <Form.Label>Número</Form.Label>
                          <Form.Control placeholder="Número" {...register('numero')} />
                        </Col>
                        <Col>
                          <Form.Label>Complemento</Form.Label>
                          <Form.Control placeholder="Complemento" {...register('complemento')} />
                        </Col>
                        <Col>
                          <Form.Label>Bairro</Form.Label>
                          <Form.Control placeholder="Bairro" {...register('bairro')} />
                        </Col>
                        <Col>
                          <Form.Label>Cidade</Form.Label>
                          <Form.Control placeholder="Cidade" {...register('cidade')} />
                        </Col>
                        <Col>
                          <Form.Label>Estado</Form.Label>
                          <Form.Select aria-label="UF" {...register('uf')}>
                            <option>Selecione a UF</option>
                            {EstadosBrasil.map((item) => {
                              return (
                                <option key={item.sigla} value={item.sigla}>
                                  {item.nome}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="container-field">
                    <div className="title">
                      <IoPersonCircleOutline />
                      <h6>Responsável legal</h6>
                    </div>
                    <div>
                      <Row className="row-field">
                        <Col>
                          <Form.Label>Nome Responsável</Form.Label>
                          <Form.Control placeholder="Nome Responsável" {...register('nomeResponsavel')} />
                        </Col>
                      </Row>
                      <Row className="row-field">
                        <Col>
                          <Form.Label>CPF responsável</Form.Label>
                          <Form.Control placeholder="CPF responsável" {...register('cpfResponsavel')} />
                        </Col>
                        <Col>
                          <Form.Label>RG responsável</Form.Label>
                          <Form.Control placeholder="RG responsável" {...register('rgResponsavel')} />
                        </Col>
                        <Col>
                          <Form.Label>Telefone responsável</Form.Label>
                          <Form.Control placeholder="Telefone responsável" {...register('telefoneResponsavel')} />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </Container>
            </Card.Body>
          </Card>
        </Container>
      </AddPatientPageStyled>
    </ContainerSideBarComponent>
  );
};

export default AddPatientPage;
