import { useNavigate } from 'react-router-dom';

import { PublicRoutes } from '../../../main/constant/RouteConstant';
import { ForgotPasswordUseCase } from '../../../data/useCase/ForgotPasswordUseCase';

import ForgotPasswordPage from '../../../presentation/page/forgotPassword/ForgotPasswordPage';
import { UseAlert } from '../../../presentation/hook/AlertHook';

/**
 * AddPatientPageDomain - regra de negocio de Paciente
 * @constructor
 */
export const ForgotPasswordPageDomain: React.FC = () => {
  /**
   * Alertas
   */
  const { showAlert } = UseAlert();

  /**
   * navegacao da url
   */
  const navigate = useNavigate();

  /**
   * endpoint
   */
  const ENDPOINT_FORGOT_PASSWORD = process.env.REACT_APP_END_POINT_FORGOT_PASSWORD ?? '';

  /**
   * sendPassword
   * @param params
   */
  const sendPassword = async (params: { login: string }) => {
    const loginFormData = new FormData();
    loginFormData.append('username', params.login);

    await new ForgotPasswordUseCase(`${ENDPOINT_FORGOT_PASSWORD}`)
      .Post({
        data: {
          login: 'romualdo.krameck@lumegrupo.com.br',
        },
      })
      .then((response) => {
        if (response.statusCode === 200) {
          navigate(PublicRoutes.LOGIN);
        }

        showAlert({
          show: true,
          content: 'Operação realizada com sucesso',
          color: 'success',
          time: 2000,
        });
        console.log('RESPONSE: ', response);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });
  };

  return (
    <>
      <ForgotPasswordPage sendPassword={sendPassword} />
    </>
  );
};
