import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from '../../presentation/page/HomePage';
import { AddPatientPageDomain } from '../../domain/page/patiente/AddPatientPageDomain';
import { ListPatientPageDomain } from '../../domain/page/patiente/ListPatientPageDomain';
import { PrivateRoutes } from '../constant/RouteConstant';
import { AddProfessionalPageDomain } from '../../domain/page/professional/AddProfessionalPageDomain';
import { ListProfessionalPageDomain } from '../../domain/page/professional/ListProfessionalPageDomain';
import { ChangePasswordPageDomain } from '../../domain/page/password/ChangePasswordPageDomain';
import { EditClinicPageDomain } from '../../domain/page/clinic/EditClinicPageDomain';
import { ListAppointmentPageDomain } from '../../domain/page/appointment/ListAppointmentPageDomain';

/**
 * PrivateRoute - conjunto de rotas autenticadas
 * @constructor
 */
export function PrivateRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={PrivateRoutes.HOME} element={<ListAppointmentPageDomain />} />
        <Route path={PrivateRoutes.CHANGE_PASSWORD} element={<ChangePasswordPageDomain />} />
        <Route path={PrivateRoutes.EDIT_CLINIC} element={<EditClinicPageDomain />} />
        <Route path={`${PrivateRoutes.ADD_PROFESSIONAL}/:id?`} element={<AddProfessionalPageDomain />} />
        <Route path={PrivateRoutes.LIST_PROFESSIONAL} element={<ListProfessionalPageDomain />} />
        <Route path={`${PrivateRoutes.ADD_PATIENT}/:id?`} element={<AddPatientPageDomain />} />
        <Route path={PrivateRoutes.LIST_PATIENT} element={<ListPatientPageDomain />} />
        <Route path={PrivateRoutes.LIST_APPOINTMENT} element={<ListAppointmentPageDomain />} />
      </Routes>
    </BrowserRouter>
  );
}

export default PrivateRoute;
