import { useNavigate } from 'react-router-dom';

import LoginPage from '../../../presentation/page/auth/LoginPage';

import { LoginUseCase } from '../../../data/useCase/LoginUseCase';
import { PrivateRoutes } from '../../../main/constant/RouteConstant';
import { setLocalStorege } from '../../../infra/cache/LocalstorageCache';
import { UseAlert } from '../../../presentation/hook/AlertHook';

/**
 * LoginPageDomain - regra de negocio de login
 * @constructor
 */
export const LoginPageDomain: React.FC = () => {
  /**
   * Alertas
   */
  const { showAlert } = UseAlert();

  /**
   * navegacao da url
   */
  let navigate = useNavigate();

  /**
   * endpoint
   */
  const ENDPOINT_LOGIN = process.env.REACT_APP_END_POINT_LOGIN ?? '';

  /**
   * chave do localstorage
   */
  const TOKEN_USER_LOGIN = process.env.LOCALSTORAGE_TOKEN_USER_LOGIN ?? '';

  /**
   * authentication
   * @param params
   */
  const authentication = async (params: { login: string; password: string }) => {
    const loginFormData = new FormData();
    loginFormData.append('username', params.login);
    loginFormData.append('password', params.password);

    await new LoginUseCase(`${ENDPOINT_LOGIN}`)
      .Post({
        data: {
          // login: params.login,
          // password: params.password,
          login: 'romualdo.krameck@lumegrupo.com.br',
          password: 'ReAZTrSa',
        },
      })
      .then((response) => {
        if (response.statusCode === 200) {
          navigate(PrivateRoutes.HOME);
          setLocalStorege(TOKEN_USER_LOGIN, response.body);
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });
  };

  return (
    <>
      <LoginPage authentication={authentication} />
    </>
  );
};
