import { AuthCall } from '../../infra/call/auth/AuthCall';
import { RequestApi } from '../../domain/contract/HttpContract';

/**
 * LoginUseCase
 * responsável pelas requisições de login
 */
export class LoginUseCase {
  constructor(private readonly endPoint: string) {
    this.endPoint = endPoint;
  }

  /**
   * Post
   * @param request
   * @constructor
   */
  async Post(request?: RequestApi) {
    return await AuthCall({
      url: this.endPoint,
      method: 'POST',
      body: request?.data,
      headers: request?.headers,
    }).then((response) => response);
  }
}
