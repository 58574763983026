import { useNavigate } from 'react-router-dom';

import { LoginPageStyled } from './LoginPageStyled';
import { AuthStyled } from '../../style/auth/authStyled';

import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { Button, ButtonGroup, Image } from 'react-bootstrap';

import logo from '../../images/logo_branco.png';
import { PublicRoutes } from '../../../main/constant/RouteConstant';

interface InputsLogin {
  login: string;
  password: string;
  email: string;
}

type Props = {
  authentication: (params: { login: string; password: string }) => Promise<any>;
};

/**
 * LoginPage
 * @param authentication
 * @constructor
 */
const LoginPage: React.FC<Props> = ({ authentication }: Props) => {
  /** useForm */
  const { register, getValues } = useForm<InputsLogin>();

  /**
   * navegacao da url
   */
  let navigate = useNavigate();

  /**
   * handleLogin
   */
  const handleLogin = async () => {
    await authentication({ login: getValues('login'), password: getValues('password') });
  };

  /**
   * handleEsqueciMinhaSenha
   */
  const handleEsqueciMinhaSenha = async () => {
    navigate(PublicRoutes.FORGOT_PASSWORD);
  };

  /**
   * handleNovaClinica
   */
  const handleNovaClinica = async () => {
    navigate(PublicRoutes.CREATE_CLINIC);
  };

  return (
    <AuthStyled>
      <LoginPageStyled>
        <div className="imagen">
          <Image src={logo} width={400} />
        </div>
        <div className="form-login">
          <Form>
            <Form.Group className="mb-3 label-email" controlId="formGroupEmail">
              <Form.Label>Login</Form.Label>
              <Form.Control className="input-email" type="email" {...register('login')} />
            </Form.Group>
            <Form.Group className="mb-3 label-senha" controlId="formGroupPassword">
              <Form.Label>Senha</Form.Label>
              <Form.Control className="input-senha" type="password" {...register('password')} />
            </Form.Group>
            <Button className="btn-entrar" size="lg" variant="primary" onClick={handleLogin}>
              Entrar
            </Button>

            <Button className="link-esqueci-senha" size="lg" variant="link" onClick={handleEsqueciMinhaSenha}>
              Esqueci minha senha
            </Button>
          </Form>
        </div>
        <div className="btn-footer">
          <Button className="btn-nova-clinica" size="lg" variant="outline-primary" onClick={handleNovaClinica}>
            Cadastrar nova clínica
          </Button>
          <p className="text-footer">
            2016-2023 Lume Tecnologia - v.0.0.1.1 - (18-09-2024)
            <br />
            Fale conosco:atendimento@lumetec.com.br
          </p>
        </div>
      </LoginPageStyled>
    </AuthStyled>
  );
};

export default LoginPage;
