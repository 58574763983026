import { UseSidebar } from '../../hook/SidebarHook';
import { Container } from 'react-bootstrap';
import SidebarComponent from '../sidebar/SidebarComponent';
import React, { ReactNode } from 'react';
import LoadingComponent from '../loading/LoadingComponent';

interface ContainerSideBarComponentProps {
  children: ReactNode;
}

/**
 * ContainerSideBarComponent - Componente de menu lateral com container
 * @param children
 * @constructor
 */
const ContainerSideBarComponent: React.FC<ContainerSideBarComponentProps> = ({ children }) => {
  /**
   * verifica se a side bar esta aberta ou fechada
   */
  const { open } = UseSidebar();

  return (
    <>
      <Container fluid>
        <div>
          <LoadingComponent>
            <SidebarComponent />
            <div style={{ marginLeft: open === true ? '12%' : '5%' }}>
              <LoadingComponent>
                <Container fluid style={{ paddingBottom: '1%' }}>
                  {children}
                </Container>
              </LoadingComponent>
            </div>
          </LoadingComponent>
        </div>
      </Container>
    </>
  );
};

export default ContainerSideBarComponent;
