import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ForgotPasswordPageStyled } from './ForgotPasswordPageStyled';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';

import logo from '../../images/logo_branco.png';

import { TfiSave } from 'react-icons/tfi';
import { FaArrowLeft } from 'react-icons/fa';

import { PrivateRoutes } from '../../../main/constant/RouteConstant';

interface InputsForgotPassword {
  login: string;
}

type Props = {
  sendPassword: (params: { login: string }) => Promise<any>;
};

/**
 * ForgotPasswordPage
 * @param sendPassword
 * @constructor
 */
const ForgotPasswordPage: React.FC<Props> = ({ sendPassword }: Props) => {
  /**
   * navega pela url
   */
  let navigate = useNavigate();

  /**
   * useForm
   */
  const { register, getValues } = useForm<InputsForgotPassword>();

  /**
   * handleVoltar
   */
  const handleVoltar = async () => {
    navigate(PrivateRoutes.HOME);
  };

  /**
   * handleSend
   */
  const handleSend = async () => {
    await sendPassword({ login: getValues('login') });
  };

  return (
    <ForgotPasswordPageStyled>
      <Container className="container-header">
        <div className="imagen">
          <Image src={logo} width={200} />
        </div>
      </Container>
      <Container className="container-field">
        <Form>
          <div>
            <div>
              <div>
                <h3>Esqueci minha senha</h3>
              </div>
              <div>
                <Row className="row-field">
                  <Col>
                    <Button size="sm" style={{ float: 'right' }} onClick={handleSend}>
                      <TfiSave width={30} style={{ paddingRight: '5px' }} />
                      Enviar
                    </Button>
                    <Button size="sm" variant="outline-primary" style={{ float: 'right', marginRight: '5px' }} onClick={handleVoltar}>
                      <FaArrowLeft width={30} style={{ paddingRight: '5px' }} />
                      Voltar
                    </Button>
                  </Col>
                </Row>
                <Row className="row-field">
                  <Col>
                    <Form.Control placeholder="Login" />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Form>
      </Container>
    </ForgotPasswordPageStyled>
  );
};

export default ForgotPasswordPage;
