import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LoginPageDomain } from '../../domain/page/auth/LoginPageDomain';
import { CreateClinicPageDomain } from '../../domain/page/clinic/CreateClinicPageDomain';
import { ForgotPasswordPageDomain } from '../../domain/page/password/ForgotPasswordPageDomain';
import { PublicRoutes } from '../constant/RouteConstant';

/**
 * PublicRoute - conjunto de rotas publicas
 * @constructor
 */
export function PublicRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={PublicRoutes.LOGIN} element={<LoginPageDomain />} />
        <Route path={PublicRoutes.CREATE_CLINIC} element={<CreateClinicPageDomain />} />
        <Route path={PublicRoutes.FORGOT_PASSWORD} element={<ForgotPasswordPageDomain />} />
      </Routes>
    </BrowserRouter>
  );
}

export default PublicRoute;
