import styled from 'styled-components';

export const ForgotPasswordPageStyled = styled.div`
  background-color: #f4f3f3;

  .container-header {
    min-height: 10vh;
    background: linear-gradient(30deg, #9459d0 0%, #027c8b 50%, #0d4693 100%);
    max-width: 100%;

    .imagen {
      text-align: center;
    }
  }

  .container-field {
    min-height: 90vh;
    max-width: 100%;
    width: 40%;
    padding-top: 2%;

    .row-field {
      padding: 5px 0px 0px 0px;
    }
  }

  .form-control:focus {
    border-color: #9459d0;
    box-shadow: 0 0 0 0.25rem rgba(128, 0, 128, 0.25);
  }

  .form-select:focus {
    border-color: #9459d0;
    box-shadow: 0 0 0 0.25rem rgba(128, 0, 128, 0.25);
  }
`;
