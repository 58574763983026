import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { EditClinicPageStyled } from './EditClinicPageStyled';
import { EstadosBrasil } from '../../../main/constant/UfConstant';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import ContainerSideBarComponent from '../../component/container/ContainerSidebarComponent';

import { TfiSave } from 'react-icons/tfi';
import { FaArrowLeft } from 'react-icons/fa';
import { PiIdentificationCard } from 'react-icons/pi';
import { IoLocationOutline, IoPersonCircleOutline } from 'react-icons/io5';

import { InputsClinic, PropsEditClinic } from './EditClinicPageType';

const EditClinicPage: React.FC<PropsEditClinic> = ({ saveClinic, clinic, comeBack }: PropsEditClinic) => {
  /** useForm */
  const { register, getValues, setValue } = useForm<InputsClinic>();

  /**
   * handleSaveClinic
   */
  const handleSaveClinic = async () => {
    const model = {
      id: getValues('id') ?? '',
      name: getValues('name') ?? '',
      status: getValues('status') ?? '',
      tradeName: getValues('tradeName') ?? '',
      accountType: getValues('accountType') ?? '',
      document_CNPJ: getValues('document_CNPJ') ?? '',
      document_CPF: getValues('document_CPF') ?? '',
      contact_Phone: getValues('contact_Phone') ?? '',
      contact_Email: getValues('contact_Email') ?? '',
      address_ZipCode: getValues('address_ZipCode') ?? '',
      address_Street: getValues('address_Street') ?? '',
      address_Number: getValues('address_Number') ?? '',
      address_Complement: getValues('address_Complement') ?? '',
      address_Neighborhood: getValues('address_Neighborhood') ?? '',
      address_City: getValues('address_City') ?? '',
      address_State: getValues('address_State') ?? '',
      address_Country: getValues('address_Country') ?? '',
      responsible_Name: getValues('responsible_Name') ?? '',
      responsible_Email: getValues('responsible_Email') ?? '',
      responsible_Document_CRO: getValues('responsible_Document_CRO') ?? '',
      responsible_Document_RG: getValues('responsible_Document_RG') ?? '',
      responsible_Document_RG_IssuingAuthority: getValues('responsible_Document_RG_IssuingAuthority') ?? '',
      setup_NumberOfChairs: getValues('setup_NumberOfChairs') ?? '',
      setup_DentistAdmin: getValues('setup_DentistAdmin') ?? '',
      setup_StandardAppointmentTime: getValues('setup_StandardAppointmentTime') ?? '',
      setup_WeeklyWorkingHours: getValues('setup_WeeklyWorkingHours') ?? '',
      setup_WeekDays: getValues('setup_WeekDays') ?? '',
    };

    await saveClinic(model);
  };

  /**
   * setModel
   * @param model
   */
  const setModel = (model: any) => {
    if (model !== null) {
      setValue('id', model.id);
      setValue('name', model.name);
      setValue('status', model.status);
      setValue('tradeName', model.tradeName);
      setValue('accountType', model.accountType);
      setValue('document_CNPJ', model.document_CNPJ);
      setValue('document_CPF', model.document_CPF);
      setValue('contact_Phone', model.contact_Phone);
      setValue('contact_Email', model.contact_Email);
      setValue('address_ZipCode', model.address_ZipCode);
      setValue('address_Street', model.address_Street);
      setValue('address_Number', model.address_Number);
      setValue('address_Complement', model.address_Complement);
      setValue('address_Neighborhood', model.address_Neighborhood);
      setValue('address_City', model.address_City);
      setValue('address_State', model.address_State);
      setValue('address_Country', model.address_Country);
      setValue('responsible_Name', model.responsible_Name);
      setValue('responsible_Email', model.responsible_Email);
      setValue('responsible_Document_CRO', model.responsible_Document_CRO);
      setValue('responsible_Document_RG', model.responsible_Document_RG);
      setValue('responsible_Document_RG_IssuingAuthority', model.responsible_Document_RG_IssuingAuthority);
      setValue('setup_NumberOfChairs', model.setup_NumberOfChairs);
      setValue('setup_DentistAdmin', model.setup_DentistAdmin);
      setValue('setup_StandardAppointmentTime', model.setup_StandardAppointmentTime);
      setValue('setup_WeeklyWorkingHours', model.setup_WeeklyWorkingHours);
      setValue('setup_WeekDays', model.setup_WeekDays);
    }
  };

  /**
   * inicio
   */
  useEffect(() => {
    if (clinic !== null) {
      setModel(clinic);
    }
  }, [clinic]);

  return (
    <ContainerSideBarComponent>
      <EditClinicPageStyled>
        <Container fluid className="title-page">
          <h3>Clínica</h3>
        </Container>

        <Container fluid>
          <Card>
            <Card.Body>
              <Container fluid>
                <Form>
                  <input type="hidden" id="id" {...register('id')} />
                  <input type="hidden" id="status" {...register('status')} />

                  <div className="container-field">
                    <Row className="row-field">
                      <Col>
                        <Button size="sm" style={{ float: 'right' }} onClick={handleSaveClinic}>
                          <TfiSave width={30} style={{ paddingRight: '5px' }} />
                          Salvar
                        </Button>
                        <Button size="sm" variant="outline-primary" style={{ float: 'right', marginRight: '5px' }} onClick={comeBack}>
                          <FaArrowLeft width={30} style={{ paddingRight: '5px' }} />
                          Voltar
                        </Button>
                      </Col>
                    </Row>
                    <div className="title">
                      <PiIdentificationCard />
                      <h6>Cadastro da Clínica</h6>
                    </div>

                    <Row className="row-field">
                      <Col>
                        <Form.Label>Nome Fantasia</Form.Label>
                        <Form.Control placeholder="Nome Fantasia" {...register('tradeName')} />
                      </Col>
                      <Col>
                        <Form.Label>CNPJ</Form.Label>
                        <Form.Control placeholder="CNPJ" {...register('document_CNPJ')} />
                      </Col>
                    </Row>
                    <Row className="row-field">
                      <Col>
                        <Form.Label>Nome da Clínica</Form.Label>
                        <Form.Control placeholder="Nome da Clínica" {...register('name')} />
                      </Col>
                      <Col>
                        <Form.Label>Email</Form.Label>
                        <Form.Control placeholder="Email" {...register('contact_Email')} />
                      </Col>
                      <Col>
                        <Form.Label>Telefone</Form.Label>
                        <Form.Control placeholder="Telefone" {...register('contact_Phone')} />
                      </Col>
                      <Col>
                        <Form.Label>Administrador da clínica</Form.Label>
                        <Form.Control placeholder="Administrador da clínica" {...register('responsible_Name')} />
                      </Col>
                      <Col>
                        <Form.Label>CRO responsável</Form.Label>
                        <Form.Control placeholder="CRO responsável" {...register('responsible_Document_CRO')} />
                      </Col>
                    </Row>
                  </div>
                  <div className="container-field">
                    <div className="title">
                      <IoLocationOutline />
                      <h6>Endereço</h6>
                    </div>
                    <div>
                      <Row className="row-field">
                        <Col>
                          <Form.Label>Cep</Form.Label>
                          <Form.Control placeholder="Cep" {...register('address_ZipCode')} />
                        </Col>
                        <Col>
                          <Form.Label>Endereço</Form.Label>
                          <Form.Control placeholder="Endereço" {...register('address_Street')} />
                        </Col>
                      </Row>
                      <Row className="row-field">
                        <Col>
                          <Form.Label>Número</Form.Label>
                          <Form.Control placeholder="Número" {...register('address_Number')} />
                        </Col>
                        <Col>
                          <Form.Label>Complemento</Form.Label>
                          <Form.Control placeholder="Complemento" {...register('address_Complement')} />
                        </Col>
                        <Col>
                          <Form.Label>Bairro</Form.Label>
                          <Form.Control placeholder="Bairro" {...register('address_Neighborhood')} />
                        </Col>
                        <Col>
                          <Form.Label>Cidade</Form.Label>
                          <Form.Control placeholder="Cidade" {...register('address_City')} />
                        </Col>
                        <Col>
                          <Form.Label>Estado</Form.Label>
                          <Form.Select aria-label="UF" {...register('address_State')}>
                            <option>Selecione a UF</option>
                            {EstadosBrasil.map((item) => {
                              return (
                                <option key={item.sigla} value={item.sigla}>
                                  {item.nome}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="container-field">
                    <div className="title">
                      <IoPersonCircleOutline />
                      <h6>Configuração da clínica</h6>
                    </div>
                    <div>
                      <Row className="row-field">
                        <Col>
                          <Form.Label>Quantidade de cadeira</Form.Label>
                          <Form.Control placeholder="Quantidade de cadeira" {...register('setup_NumberOfChairs')} />
                        </Col>
                        <Col>
                          <Form.Label>Horas úteis semanais</Form.Label>
                          <Form.Control placeholder="horas úteis semanais" {...register('setup_WeeklyWorkingHours')} />
                        </Col>
                        <Col>
                          <Form.Label>Tempo padrão da consulta(Min)</Form.Label>
                          <Form.Control placeholder="Tempo padrão da consulta(Min)" {...register('setup_StandardAppointmentTime')} />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </Container>
            </Card.Body>
          </Card>
        </Container>
      </EditClinicPageStyled>
    </ContainerSideBarComponent>
  );
};

export default EditClinicPage;
