import { Container, Pagination } from 'react-bootstrap';
import { PaginationComponentProps } from '../../../domain/contract/Pagination';

/**
 * PaginationComponent - Componente de paginacao
 * @param onClickItem
 * @param totalPages
 * @param pageIndex
 * @param hasPreviousPage
 * @param hasNextPage
 * @constructor
 */
const PaginationComponent: React.FC<PaginationComponentProps> = ({
  onClickItem,
  totalPages,
  pageIndex,
  hasPreviousPage,
  hasNextPage,
}: PaginationComponentProps) => {
  return (
    <Container fluid>
      <Pagination size="sm">
        {hasPreviousPage && (
          <Pagination.Prev
            onClick={() => {
              if (pageIndex > 1) {
                onClickItem && onClickItem(pageIndex - 2);
              }
            }}>
            Anterior
          </Pagination.Prev>
        )}

        {Array.from({ length: Math.min(6, totalPages) }, (_, index) => {
          const startPage = Math.max(1, Math.min(pageIndex - Math.floor(6 / 2), totalPages - 6));
          const page = startPage + index;

          return (
            <Pagination.Item key={page} active={page === pageIndex} onClick={() => onClickItem && onClickItem(page - 1)}>
              {page}
            </Pagination.Item>
          );
        })}

        {hasNextPage && (
          <Pagination.Next
            onClick={() => {
              if (pageIndex < totalPages) {
                onClickItem && onClickItem(pageIndex);
              }
            }}>
            Próxima
          </Pagination.Next>
        )}
      </Pagination>
    </Container>
  );
};

export default PaginationComponent;
