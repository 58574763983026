import { useEffect, useState } from 'react';

import EditClinicPage from '../../../presentation/page/editClinic/EditClinicPage';

import { useNavigate } from 'react-router-dom';
import { ClinicUseCase } from '../../../data/useCase/ClinicUseCase';
import { PrivateRoutes } from '../../../main/constant/RouteConstant';
import { UseAlert } from '../../../presentation/hook/AlertHook';

/**
 * EditClinicPageDomain - regra de negocio de clinica
 * @constructor
 */
export const EditClinicPageDomain: React.FC = () => {
  /**
   * Alertas
   */
  const { showAlert } = UseAlert();

  /**
   * navegacao da url
   */
  let navigate = useNavigate();

  /**
   * endpoint
   */
  const ENDPOINT_CLINIC = process.env.REACT_APP_END_POINT_CLINIC ?? '';

  /**
   * model
   */
  const [clinic, setClinic] = useState<null>(null);

  /**
   * saveClinic
   * @param params
   */
  const saveClinic = async (params: any) => {
    let model = {
      id: params.id,
      name: params.name,
      status: params.status ? params.status : 'A',
      tradeName: params.tradeName,
      accountType: params.accountType ? params.accountType : 'J',
      document_CNPJ: params.document_CNPJ,
      document_CPF: params.document_CPF,
      contact_Phone: params.contact_Phone,
      contact_Email: params.contact_Email,
      address_ZipCode: params.address_ZipCode,
      address_Street: params.address_Street,
      address_Number: params.address_Number,
      address_Complement: params.address_Complement,
      address_Neighborhood: params.address_Neighborhood,
      address_City: params.address_City,
      address_State: params.address_State,
      address_Country: params.address_Country,
      responsible_Name: params.responsible_Name ? params.responsible_Name : 'responsible_Name',
      responsible_Email: params.responsible_Email ? params.responsible_Email : params.contact_Email,
      responsible_Document_CRO: params.responsible_Document_CRO ? params.responsible_Document_CRO : 'responsible_Document_CRO',
      responsible_Document_RG: params.responsible_Document_RG ? params.responsible_Document_RG : 'responsible_Document_RG',
      responsible_Document_RG_IssuingAuthority: params.responsible_Document_RG_IssuingAuthority ? params.responsible_Document_RG_IssuingAuthority : 'SSP',
      setup_NumberOfChairs: params.setup_NumberOfChairs,
      setup_DentistAdmin: params.setup_DentistAdmin,
      setup_StandardAppointmentTime: params.setup_StandardAppointmentTime,
      setup_WeeklyWorkingHours: params.setup_WeeklyWorkingHours,
      setup_WeekDays: params.setup_WeekDays,
    };
    await new ClinicUseCase(`${ENDPOINT_CLINIC}/${params.id}`)
      .Put({
        data: model,
      })
      .then((response) => {
        showAlert({
          show: true,
          content: 'Operação realizada com sucesso',
          color: 'success',
          time: 2000,
        });
        console.log('RESPONSE: ', response);
      })
      .catch((error) => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
          color: 'danger',
          time: 2000,
        });
        console.error('ERRO: ', error);
      });

    await getClinic();
  };

  /**
   * comeBack - voltar
   */
  const comeBack = () => {
    navigate(PrivateRoutes.HOME);
  };

  /**
   * getClinic
   */
  const getClinic = async () => {
    await new ClinicUseCase(`${ENDPOINT_CLINIC}`)
      .Get()
      .then((response) => {
        setClinic(response.body);
      })
      .catch((error) => {});
  };

  /**
   * inicia preenchendo a clinica
   */
  const init = async () => {
    await getClinic();
  };

  /**
   * inicio
   */
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <EditClinicPage saveClinic={saveClinic} comeBack={comeBack} clinic={clinic} />
    </>
  );
};
