import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { EstadosBrasil } from '../../../main/constant/UfConstant';
import { AddProfessionalPageStyled } from './AddProfessionalPageStyled';

import PaginationComponent from '../../component/pagination/PaginationComponent';
import ContainerSideBarComponent from '../../component/container/ContainerSidebarComponent';

import { TfiSave } from 'react-icons/tfi';
import { FaArrowLeft } from 'react-icons/fa';
import { PiIdentificationCard } from 'react-icons/pi';
import { IoLocationOutline, IoPersonCircleOutline } from 'react-icons/io5';

import { AddProfessional, EditProfessional, ListProfessional, PropsAddProfessional } from './AddProfessionalPageType';

/**
 * AddProfessionalPage
 * @param add
 * @param comeBack
 * @param professional
 * @param edit
 * @constructor
 */
const AddProfessionalPage: React.FC<PropsAddProfessional> = ({
  add,
  comeBack,
  professional,
  edit,
  professionalSpecialties,
  specialtiesList,
  specialtiesPagination,
}: PropsAddProfessional) => {
  /**
   * mostra modal
   */
  const [show, setShow] = useState(false);

  /**
   * handleClose - fecha modal
   */
  const handleClose = () => setShow(false);

  /**
   * handleShow - abre modal
   */
  const handleShow = () => setShow(true);

  /** useForm */
  const { register, getValues, setValue } = useForm<ListProfessional>();

  /**
   * handleAdd - Cria professional
   */
  const handleAdd = async () => {
    const model: AddProfessional = {
      id: getValues('id') ?? '',
      status: 'A',
      name: getValues('name') ?? '',
      document: getValues('document') ?? '',
      cellPhone: getValues('cellPhone') ?? '',
      email: getValues('email') ?? '',
      profileDescription: getValues('profileDescription') ?? '',
      appointmentTime: getValues('appointmentTime') ?? '',
      birthDate: getValues('birthDate') ?? '',
      boardRegistration: getValues('boardRegistration') ?? '',
      prefix: getValues('prefix') ?? '',
      document_RG: getValues('document_RG') ?? '',
      stateCouncil: getValues('stateCouncil') ?? '',
      gender: getValues('gender') ?? '',
      address_PostalCode: getValues('address_PostalCode') ?? '',
      address_Street: getValues('address_Street') ?? '',
      address_Number: getValues('address_Number') ?? '',
      address_Complement: getValues('address_Complement') ?? '',
      address_Neighborhood: getValues('address_Neighborhood') ?? '',
      address_City: getValues('address_City') ?? '',
      address_State: getValues('address_State') ?? '',
      specialties: getValues('specialties') ?? [],
    };

    await add(model);
  };

  /**
   * handleEdit - Edita professional
   */
  const handleEdit = async () => {
    const model: EditProfessional = {
      id: getValues('id') ?? '',
      status: getValues('status') ?? '',
      name: getValues('name') ?? '',
      document: getValues('document') ?? '',
      cellPhone: getValues('cellPhone') ?? '',
      email: getValues('email') ?? '',
      profileDescription: getValues('profileDescription') ?? '',
      appointmentTime: getValues('appointmentTime') ?? '',
      birthDate: getValues('birthDate') ?? '',
      boardRegistration: getValues('boardRegistration') ?? '',
      prefix: getValues('prefix') ?? '',
      document_RG: getValues('document_RG') ?? '',
      stateCouncil: getValues('stateCouncil') ?? '',
      gender: getValues('gender') ?? '',
      address_PostalCode: getValues('address_PostalCode') ?? '',
      address_Street: getValues('address_Street') ?? '',
      address_Number: getValues('address_Number') ?? '',
      address_Complement: getValues('address_Complement') ?? '',
      address_Neighborhood: getValues('address_Neighborhood') ?? '',
      address_City: getValues('address_City') ?? '',
      address_State: getValues('address_State') ?? '',
      specialties: getValues('specialties') ?? [],
    };

    await edit(model);
  };

  /**
   * setModel - Preenche model
   * @param model
   */
  const setModel = (model: any) => {
    if (model !== null) {
      setValue('id', model.id);
      setValue('status', model.status);
      setValue('name', model.name);
      setValue('document', model.document);
      setValue('cellPhone', model.cellPhone);
      setValue('email', model.email);
      setValue('profileDescription', model.profileDescription);
      setValue('appointmentTime', model.appointmentTime);
      setValue('birthDate', '1985-01-21T00:00:00');
      setValue('boardRegistration', model.boardRegistration);
      setValue('prefix', model.prefix);
      setValue('document_RG', model.document_RG);
      setValue('stateCouncil', model.stateCouncil);
      setValue('gender', model.gender);
      setValue('address_PostalCode', model.address_PostalCode);
      setValue('address_Street', model.address_Street);
      setValue('address_Number', model.address_Number);
      setValue('address_Complement', model.address_Complement);
      setValue('address_Neighborhood', model.address_Neighborhood);
      setValue('address_City', model.address_City);
      setValue('address_State', model.address_State);
      setValue('specialties', model.specialties);
      setValue('clinicId', model.clinicId);
      setValue('userId', model.userId);
    }
  };

  /**
   * inicio
   */
  useEffect(() => {
    console.log('useEffect > professional > ', professional);
    setModel(professional);
  }, [professional]);

  return (
    <ContainerSideBarComponent>
      <AddProfessionalPageStyled>
        <Container fluid className="title-page">
          {professional && <h3>{`Profissional - ${professional.name}`}</h3>}
          {professional === null && <h3>Novo Profissional</h3>}
        </Container>

        <Container fluid>
          <Card>
            <Card.Body>
              <Container fluid>
                <Form>
                  <input type="hidden" id="id" {...register('id')} />
                  <input type="hidden" id="status" {...register('status')} />
                  <input type="hidden" id="boardRegistration" {...register('boardRegistration')} />
                  <input type="hidden" id="appointmentTime" {...register('appointmentTime')} />
                  <input type="hidden" id="clinicId" {...register('clinicId')} />
                  <input type="hidden" id="userId" {...register('userId')} />

                  <div className="container-field">
                    <Row className="row-field">
                      <Col>
                        <Button size="sm" style={{ float: 'right' }} onClick={professional === null ? handleAdd : handleEdit}>
                          <TfiSave width={30} style={{ paddingRight: '5px' }} />
                          Salvar
                        </Button>
                        <Button size="sm" variant="outline-primary" style={{ float: 'right', marginRight: '5px' }} onClick={comeBack}>
                          <FaArrowLeft width={30} style={{ paddingRight: '5px' }} />
                          Voltar
                        </Button>
                      </Col>
                    </Row>
                    <div className="title">
                      <PiIdentificationCard />
                      <h6>Dados Pessoais</h6>
                    </div>
                    <Row className="row-field">
                      <Col sm={2}>
                        <Form.Label>Prefixo</Form.Label>
                        <Form.Control placeholder="Prefixo" {...register('prefix')} />
                      </Col>
                      <Col>
                        <Form.Label>Nome *</Form.Label>
                        <Form.Control placeholder="Nome *" {...register('name')} />
                      </Col>
                    </Row>
                    <Row className="row-field">
                      <Col>
                        <Form.Label>Perfil</Form.Label>
                        <Form.Control placeholder="Perfil" {...register('profileDescription')} />
                      </Col>
                      <Col>
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control placeholder="Descrição" {...register('profileDescription')} />
                      </Col>
                    </Row>
                    <Row className="row-field">
                      <Col>
                        <Form.Label>E-mail *</Form.Label>
                        <Form.Control placeholder="E-mail *" {...register('email')} />
                      </Col>
                      <Col>
                        <Form.Label>Celular</Form.Label>
                        <Form.Control placeholder="Celular" {...register('cellPhone')} />
                      </Col>
                    </Row>
                    <Row className="row-field">
                      <Col>
                        <Form.Label>CPF</Form.Label>
                        <Form.Control placeholder="CPF" {...register('document')} />
                      </Col>
                      <Col>
                        <Form.Label>RG</Form.Label>
                        <Form.Control placeholder="RG" {...register('document_RG')} />
                      </Col>
                      <Col>
                        <Form.Label>Data nascimento</Form.Label>
                        <Form.Control type="date" placeholder="Data nascimento" {...register('birthDate')} />
                      </Col>
                      <Col>
                        <Form.Label>Sexo</Form.Label>
                        <Form.Check inline type="radio" label="Feminino" value="F" {...register('gender')} />
                        <Form.Check inline type="radio" label="Masculino" value="M" {...register('gender')} />
                      </Col>
                    </Row>
                  </div>
                  <div className="container-field">
                    <div className="title">
                      <IoLocationOutline />
                      <h6>Especialidades</h6>
                    </div>
                    <div>
                      <Row className="row-field">
                        <Col>
                          {professionalSpecialties !== null &&
                            professionalSpecialties.map((item: string, index: number) => {
                              return (
                                <Badge style={{ marginRight: '2px' }} key={index} bg="secondary">
                                  {item}
                                </Badge>
                              );
                            })}
                        </Col>
                      </Row>

                      <Row className="row-field">
                        <Col>
                          <Button size="sm" variant="outline-primary" onClick={handleShow}>
                            Adicionar especialidades
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="container-field">
                    <div className="title">
                      <IoLocationOutline />
                      <h6>Endereço</h6>
                    </div>
                    <div>
                      <Row className="row-field">
                        <Col>
                          <Form.Label>Cep</Form.Label>
                          <Form.Control placeholder="Cep" {...register('address_PostalCode')} />
                        </Col>
                        <Col>
                          <Form.Label>Endereço</Form.Label>
                          <Form.Control placeholder="Endereço" {...register('address_Street')} />
                        </Col>
                      </Row>
                      <Row className="row-field">
                        <Col>
                          <Form.Label>Número</Form.Label>
                          <Form.Control placeholder="Número" {...register('address_Number')} />
                        </Col>
                        <Col>
                          <Form.Label>Complemento</Form.Label>
                          <Form.Control placeholder="Complemento" {...register('address_Complement')} />
                        </Col>
                        <Col>
                          <Form.Label>Bairro</Form.Label>
                          <Form.Control placeholder="Bairro" {...register('address_Neighborhood')} />
                        </Col>
                        <Col>
                          <Form.Label>Cidade</Form.Label>
                          <Form.Control placeholder="Cidade" {...register('address_City')} />
                        </Col>
                        <Col>
                          <Form.Label>Estado</Form.Label>
                          <Form.Select aria-label="UF" {...register('address_State')}>
                            <option>Selecione a UF</option>
                            {EstadosBrasil.map((item) => {
                              return (
                                <option key={item.sigla} value={item.sigla}>
                                  {item.nome}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="container-field">
                    <div className="title">
                      <IoPersonCircleOutline />
                      <h6>Dados legais</h6>
                    </div>
                    <div>
                      <Row className="row-field">
                        <Col>
                          <Form.Label>Registro do conselho</Form.Label>
                          <Form.Control placeholder="Registro do conselho" />
                        </Col>
                      </Row>
                      <Row className="row-field">
                        <Col>
                          <Form.Label>Estado</Form.Label>
                          <Form.Select aria-label="UF" {...register('stateCouncil')}>
                            <option>Estado conselho</option>
                            {EstadosBrasil.map((item) => {
                              return (
                                <option key={item.sigla} value={item.sigla}>
                                  {item.nome}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Col>
                        <Col>
                          <Form.Label>Tempo consulta(min)</Form.Label>
                          <Form.Control placeholder="Tempo consulta(min)" {...register('id')} />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </Container>
            </Card.Body>
          </Card>
        </Container>
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Especialidades</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid className="table-especialidade-page">
              <Card>
                <Card.Body>
                  <Table striped={false} bordered={false} borderless={false} hover size="sm">
                    <thead>
                      <tr>
                        <th>Descrição</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {specialtiesList.map((item: any) => {
                        return (
                          <tr key={item.id}>
                            <td>{item.description}</td>
                            <td>
                              <Row className="">
                                <Col className="table-btn">
                                  <Button size="sm" variant="outline-primary" style={{ float: 'right' }} onClick={() => console.log(item.id)}>
                                    <span>Adicionar</span>
                                  </Button>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <PaginationComponent
                    totalPages={specialtiesPagination?.totalPages}
                    pageIndex={specialtiesPagination?.pageIndex}
                    onClickItem={specialtiesPagination?.onClickItem}
                    hasPreviousPage={specialtiesPagination?.hasPreviousPage}
                    hasNextPage={specialtiesPagination?.hasNextPage}
                  />
                </Card.Body>
              </Card>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </AddProfessionalPageStyled>
    </ContainerSideBarComponent>
  );
};

export default AddProfessionalPage;
