import React, { useEffect, useState } from 'react';

import ContainerSideBarComponent from '../../component/container/ContainerSidebarComponent';
import { EditAppointment, InputsAppointment, InputsSearchAppointment, PropsListAppointment } from './ListAppointmentPageType';
import { CanvasPageStyled, ListAppointmentPageStyled } from './ListAppointmentPageStyled';
import { Button, Col, Container, Form, Offcanvas, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { Controller, useForm } from 'react-hook-form';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

import pt_br from '@fullcalendar/core/locales/pt-br';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const ListAppointmentPage: React.FC<PropsListAppointment> = ({
  listAppointment,
  add,
  search,
  meses,
  anos,
  defaultDate,
  edit,
  getAppointment,
  appointment,
  listPatient,
  listProfessional,
}: PropsListAppointment) => {
  /**
   * useForm
   */
  const { register, getValues, setValue, control } = useForm<InputsAppointment>();

  /**
   * mostra modal
   */
  const [show, setShow] = useState(false);

  /**
   * handleClose - fecha modal
   */
  const handleClose = () => setShow(false);

  /**
   * handleShow - abre modal
   */
  const handleShow = () => setShow(true);

  /**
   * handleAdd
   */
  const handleAdd = async () => {
    const model = {
      status: getValues('status') ?? '',
      patientId: getValues('patientId') ?? '',
      patientName: getValues('patientName') ?? '',
      professionalId: getValues('professionalId') ?? '',
      professionalName: getValues('professionalName') ?? '',
      start: getValues('start') ?? '',
      end: getValues('end') ?? '',
      chair: getValues('chair') ?? '',
      observation: getValues('observation') ?? '',
    };

    await add(model);
  };

  /**
   * handleEdit
   */
  const handleEdit = async () => {
    const model: EditAppointment = {
      id: getValues('id') ?? '',
      status: getValues('status') ?? '',
      patientId: getValues('patientId') ?? '',
      patientName: getValues('patientName') ?? '',
      professionalId: getValues('professionalId') ?? '',
      professionalName: getValues('professionalName') ?? '',
      start: getValues('start') ?? '',
      end: getValues('end') ?? '',
      chair: getValues('chair') ?? '',
      observation: getValues('observation') ?? '',
    };

    await edit(model);
  };

  /**
   * setModel
   * @param model
   */
  const setModel = (model: any) => {
    resetModel();
    if (model !== null) {
      console.log(model);

      setValue('id', model.id);
      setValue('status', model.status);
      setValue('patientId', model.patientId);
      setValue('patientName', model.patientName);
      setValue('professionalId', model.professionalId);
      setValue('professionalName', model.professionalName);
      setValue('start', model.start);
      setValue('end', model.end);
      setValue('chair', model.chair);
      setValue('observation', model.observation);
      setValue('scheduledBy', model.scheduledBy);
      setValue('scheduledAt', model.scheduledAt);
      setValue('clinicId', model.clinicId);
    }
  };

  /**
   * resetModel
   */
  const resetModel = () => {
    setValue('id', 0);
    setValue('status', '');
    setValue('patientId', 0);
    setValue('patientName', '');
    setValue('professionalId', 0);
    setValue('professionalName', '');
    setValue('start', '');
    setValue('end', '');
    setValue('chair', 0);
    setValue('observation', '');
    setValue('scheduledBy', 0);
    setValue('scheduledAt', '');
    setValue('clinicId', 0);
  };

  /**
   * apontamento clicado em branco no calendario
   */
  const handleSelect = () => {
    setTitle('Novo Agendamento');
    setModel(null);
    handleShow();
  };

  /**
   * apontamento selecionado no calendario
   * @param e
   */
  const handleSelectAppointment = async (e: any) => {
    setTitle(`Agendamento ${e.event.title}`);
    await getAppointment(e.event.id);
    setModel(appointment);
    handleShow();
  };

  const renderEventContent = (eventInfo: any) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };

  /**
   * Titulo da pagina
   */
  const [title, setTitle] = useState('');

  useEffect(() => {
    setModel(appointment);
  }, [appointment]);

  useEffect(() => {
    const month = defaultDate.getMonth() + 1; // Mês (adicionar +1, pois começa em 0)
    const year = defaultDate.getFullYear();

    setValue('mes', month);
    setValue('ano', year);
  }, [defaultDate]);

  return (
    <ContainerSideBarComponent>
      <ListAppointmentPageStyled>
        <Container fluid className="title-page">
          <h3>Agendamento</h3>
        </Container>
        <Container fluid>
          <Row className="row-field-filter">
            <Col sm={2}>
              <Form.Select aria-label="mes" {...register('mes')}>
                {meses.map((item, index) => {
                  return (
                    <option key={item} value={index + 1} selected={String(index + 1) == getValues('mes')}>
                      {item}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
            <Col sm={1}>
              <Form.Select aria-label="mes" {...register('ano')}>
                {anos.map((item) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>

            <Col sm={9} className="filter-btn">
              <Button
                size="sm"
                variant="outline-primary"
                style={{ float: 'right', marginRight: '5px' }}
                onClick={() =>
                  search({
                    mes: getValues('mes'),
                    ano: getValues('ano'),
                  } as InputsSearchAppointment)
                }>
                <FaSearch />
                <span>Pesquisar</span>
              </Button>
            </Col>
          </Row>
        </Container>
        <div className="calendario">
          <FullCalendar
            themeSystem={'bootstrap5'}
            locales={[pt_br]}
            plugins={[dayGridPlugin, bootstrap5Plugin, multiMonthPlugin, interactionPlugin, timeGridPlugin]}
            headerToolbar={{
              left: 'prev,next,today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            initialView="dayGridMonth"
            initialDate={defaultDate}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            events={listAppointment}
            eventClick={handleSelectAppointment}
            dateClick={handleSelect}
            eventContent={renderEventContent}
            customButtons={{
              next: {
                text: 'Próximo',
                click: async () => {
                  const month = defaultDate.getMonth() + 1;
                  const year = defaultDate.getFullYear();

                  await search({
                    mes: month,
                    ano: year,
                    sequencia: 'proximo',
                  } as InputsSearchAppointment);
                },
              },
              prev: {
                text: 'Anterior',
                click: async () => {
                  const month = defaultDate.getMonth() + 1;
                  const year = defaultDate.getFullYear();

                  await search({
                    mes: month,
                    ano: year,
                    sequencia: 'anterior',
                  } as InputsSearchAppointment);
                },
              },
              today: {
                text: 'Mês Atual',
                click: async () => {
                  const currentDate = new Date();

                  const month = currentDate.getMonth() + 1;
                  const year = currentDate.getFullYear();

                  await search({
                    mes: month.toString(),
                    ano: year.toString(),
                  } as InputsSearchAppointment);
                },
              },
            }}
          />
        </div>
        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{title}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <CanvasPageStyled>
              <Container fluid>
                <Form>
                  <input type="hidden" id="id" {...register('id')} />
                  <input type="hidden" id="patientId" {...register('patientId')} />
                  <input type="hidden" id="professionalId" {...register('professionalId')} />
                  <input type="hidden" id="scheduledBy" {...register('scheduledBy')} />
                  <input type="hidden" id="scheduledAt" {...register('scheduledAt')} />

                  <input type="hidden" id="clinicId" />

                  <div className="container-field-canvas">
                    <Row className="row-field-canvas">
                      <Col>
                        <Button size="sm" style={{ float: 'right' }} onClick={title === 'Novo Agendamento' ? handleAdd : handleEdit}>
                          Salvar
                        </Button>
                      </Col>
                    </Row>

                    <Row className="row-field-canvas">
                      <Col>
                        <Form.Group>
                          <Form.Label>Nome do Paciente</Form.Label>
                          <Controller
                            name="patientId"
                            control={control}
                            render={({ field }) => (
                              <Form.Select {...field}>
                                <option value="">Selecione o Nome do Paciente</option>
                                {listPatient.map((item: any) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </Form.Select>
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="row-field-canvas">
                      <Col>
                        <Form.Group>
                          <Form.Label>Nome do Profissional</Form.Label>
                          <Controller
                            name="professionalId"
                            control={control}
                            render={({ field }) => (
                              <Form.Select {...field}>
                                <option value="">Selecione o Nome do Profissional</option>
                                {listProfessional.map((item: any) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </Form.Select>
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="row-field-canvas">
                      <Col xs={6}>
                        <Form.Label>Data Inicio</Form.Label>
                        <Form.Control type="date" {...register('start')} />
                      </Col>
                      <Col xs={6}>
                        <Form.Label>Data Fim</Form.Label>
                        <Form.Control type="date" {...register('end')} />
                      </Col>
                    </Row>
                    <Row className="row-field-canvas">
                      <Col>
                        <Form.Label>Cadeira</Form.Label>
                        <Form.Control placeholder="Cadeira" {...register('chair')} />
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Status</Form.Label>
                          <Controller
                            name="status"
                            control={control}
                            defaultValue="A"
                            render={({ field }) => (
                              <Form.Select {...field}>
                                <option value="A">Ativo</option>
                                <option value="N">Inativo</option>
                              </Form.Select>
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="row-field-canvas">
                      <Col>
                        <Form.Label>Observação</Form.Label>
                        <Form.Control placeholder="Observação" as="textarea" rows={10} {...register('observation')} />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Container>
            </CanvasPageStyled>
          </Offcanvas.Body>
        </Offcanvas>
      </ListAppointmentPageStyled>
    </ContainerSideBarComponent>
  );
};

export default ListAppointmentPage;
